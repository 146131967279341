import React, { useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import './Hero.scss'

// import Cursor from '../../../../Global/Images/cursor_hero.svg';

import Dots from '../../../../Global/Images/dots.svg'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AOS from "aos";
import "aos/dist/aos.css";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Link,
    useLocation
} from "react-router-dom";

import car_1 from '../../../../Global/Images/car_1.jpg'
import car_2 from '../../../../Global/Images/car_2.jpg'
import car_3 from '../../../../Global/Images/car_3.JPG'
import car_4 from '../../../../Global/Images/car_4.JPG'
import car_5 from '../../../../Global/Images/car_5.jpg'
import car_6 from '../../../../Global/Images/car_6.jpg'


export default function Hero() {

    const options = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        items: 3,
        navText: ["<i class='bi bi-arrow-left'></i>", "<i class='bi bi-arrow-right'></i>"],
        smartSpeed: 1000,
        autoWidth: false,

        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 3,

            }
        },
    };

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);


    return (
        <div className="hero-area">
            <Container>
                <Row>
                    <Col lg={1} md={1} sm={12} xs={12} xl={1}>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} xl={12} className="left-hero-col hero-row-flex">
                        <div className="hero-header-div">

                            <h1 className="big-header">Past Perfect
                            {/* <br></br>
                                <span className="hero-header-span">heritage management.</span> */}
                            </h1>

                            <p className="para normal mt-d">
                         <span className="hero-para-line-1">Building bridges to the past with <br></br>precision and passion.</span> 
<br></br>
Unraveling untold stories and<br></br> complex histories in all its shades.</p>
                        </div>
                        <Link to="/about-us"><button className="box-btn">Learn More <i className="bi bi-search ml-3"></i></button></Link>
                    </Col>
                    <Col lg={1} md={6} sm={12} xs={12} xl={1}></Col>
                    <Col lg={5} md={6} sm={12} xs={12} xl={5} className="side-col">

                    </Col>
                    <div className="only-mobile">
                        <div className="side-mobile">
                            <p className="side-text-1">estd. 2016</p>
                            {/* <p className="side-text-1">20+ Clients</p> */}
                        </div>
                    </div>
                </Row>
                <Row className="carousel">
                    <img src={Dots} className="dots img-fluid"></img>
                    <Col lg={1} md={1} sm={12} xs={12} xl={1}></Col>
                    <Col lg={11} md={11} sm={12} xs={12} xl={11}>
                        <OwlCarousel
                            {...options}
                        >
                            <div><img className="carousel-img" src={car_1} /></div>
                            <div><img className="carousel-img" src={car_2} /></div>
                            <div><img className="carousel-img" src={car_3} /></div>
                            <div><img className="carousel-img" src={car_4} /></div>
                            <div><img className="carousel-img" src={car_5} /></div>
                            <div><img className="carousel-img" src={car_6} /></div>
                        </OwlCarousel>
                    </Col>
                    <img src={Dots} className="lower-dots img-fluid"></img>
                </Row>
            </Container>
            {/* <img src={scrollDown} alt="scroll down" className="scroll-down"/> */}
        </div>
    )
}


