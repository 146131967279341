import React from 'react'
import Navbar from '../../Global/navbar/navbar'
import Footer from '../../Global/footer/footer.js'
import Hero from './Components/Hero/Hero.js'
import FormForm from './Components/Form/Form.js'

export default function Formus() {
    return (
        <div className="contact-page-area">
            <Navbar />
            <Hero />
            <FormForm />
            <Footer />
        </div>
    )
}