import Profile from '../../../Global/Profile/profile.js'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    Link,
    useHistory
} from "react-router-dom";
import LoadingSpinner from '../../../Global/LoadingSpinner';
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../../../../../firebase.js';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { async } from '@firebase/util';

// async function createUser(name, email, admin, password) {
//     const url = 'https://pastperfect-backend.herokuapp.com/v1/register'
//     const headers = {
//         'Content-Type': 'multipart/form-data',
//         "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
//     };

//     // use when jwt is required
//     // return axios.post(url, {
//     //     name: name,
//     //     email: email,
//     //     admin: admin,
//     //     password: password
//     // }, { headers }).then(function (response) {
//     //     return response;
//     // }).catch(function (error) {
//     //     return error;
//     // });

//     return axios.post(url, {
//         name: name,
//         email: email,
//         admin: admin,
//         password: password
//     }).then(function (response) {
//         return response;
//     }).catch(function (error) {
//         return error;
//     });


// }

const AddUser = () => {


    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [uid, setUid] = useState("");
    const [password, setPassword] = useState(false);
    const [message, setMessage] = useState();

    const history = useHistory()


    const handleSubmit = async e => {
        e.preventDefault();
        try {
            createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
                setUid(userCredential.user.uid)
            }).catch((error) => {
                setMessage("An error occured! Please try again.");
            })

        } catch (error) {
            setMessage("An error occured! Please try again.");
        }
    }

    useEffect(() => {
        try {
            (async () => {
                if (uid.length > 0) {
                    await addDoc(collection(db, "user"), { email: email, name: name, user_id: uid })
                    history.push('/dashboard/users');
                    setUid("")
                }
            })();
        } catch (error) {
            setMessage("An error occured! Please try again.");
            setUid("")
        }

    }, [uid])







    return (
        <>
            <div className="pro-row">
                <Link to='/dashboard/users'><i className="bi bi-chevron-left">back</i></Link>
                <div className="desktop-only"> <Profile /></div>
            </div>

            <Container>

                <h1 className="dashboard-header">Add User</h1>
                <p className="para mt-1">Enter the details to add a new user</p>

                <Row className="margin-dash-4">
                    <Form className="mt-5" onSubmit={handleSubmit}>

                        <Col xl={5} lg={12} cm={12} md={12} xs={12} sm={12}>

                            <Form.Group className="mb-4">
                                <p style={{ color: 'red', textAlign: 'center' }}>{message}</p>
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="formBasicName">
                                <Form.Control type="text" onChange={e => setName(e.target.value)} placeholder="Enter name" />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Control type="email" onChange={e => setEmail(e.target.value)} placeholder="Enter email" />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="formBasicPassword">
                                <Form.Control type="password" placeholder="Password" />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="formBasicPassword">
                                <Form.Control type="password" onChange={e => setPassword(e.target.value)} placeholder="Confirm Password" />
                            </Form.Group>



                            <Button className="submit-btn" type="submit">
                                Add
                            </Button>
                        </Col>

                    </Form>
                </Row>
            </Container>
        </>
    )
}

export default AddUser
