import React from 'react'
import {Row,Container, Col} from 'react-bootstrap'
import './Hero.scss'

function Hero() {
    return (
        <div className="hero-area">
            <Container>
            <Row>
                    <Col lg={1} md={2} sm={12} xs={12} xl={1}>
                    </Col>
                    <Col lg={5} md={5} sm={12} xs={12} xl={5}>
                    {/* <p className="top-para">Lorem Ipsum is simply dummy text of </p> */}
                    <h1 className="big-header">When we got featured.</h1>
                    <p className="para mt-3">Here's a glimpse into our work through the lens of the press, along with a few special features curated by our team over the years! </p>
                  
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Hero
