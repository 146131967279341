import React from 'react'
import Navbar from '../../Global/navbar/navbar'
import Footer from '../../Global/footer/footer.js'

import Hero  from './Components/Hero/Hero.js'
import Works  from './Components/Works/Works.js'

import './bg.scss'

export default function Ourworks() {
    return (
        <div className="bg-area">
            <Navbar />
            <Hero />
            <Works />
            <Footer />
        </div>
    )
}
