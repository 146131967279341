import React, { useState } from 'react'
import './Form.scss'
import { Row, Col, Container, Button, Form } from 'react-bootstrap'
import instagramLogo from '../../../../Global/Images/Instagram.svg'
import fbLogo from '../../../../Global/Images/Facebook.svg'
import twitterLogo from '../../../../Global/Images/Twitter.svg'
import axios from 'axios'
import AddWork from '../../../../../AdminSide/Screens/works/AddWork/addWork'
import LoadingSpinner from '../../../../../AdminSide/Global/LoadingSpinner'
import scrollDown from '../../../../Global/Images/scrollDown.svg'
import { addDoc, collection, serverTimestamp, Timestamp } from 'firebase/firestore'
import { db } from '../../../../../../firebase'



async function addEnquiry(data) {
    const url = 'https://pastperfect-backend.herokuapp.com/v1/contact'
    return axios.post(url, data).then(function (response) {
        return response;
    }).catch(function (error) {
        return error;
    });
}

function CForm() {

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phoneNo, setPhoneNo] = useState();
    const [service, setService] = useState("Archiving & Documentation");
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('')

    // const handleSubmit = async e => {
    //     e.preventDefault();
    //     setLoading(true);
    //     const data = new FormData();
    //     data.set('name', name);
    //     data.set('email', email);
    //     data.set('phone_no', phoneNo);
    //     data.set('service', service);
    //     data.set('message', message);

    //     const response = await addEnquiry(data);
    //     setLoading(false);
    //     if (response.status === 200) {
    //         window.location.reload(false);
    //         setResponseMessage(response.data.message);
    //     }
    // }

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        try {

            await addDoc(collection(db, "contact_form_submissions"), { name: name, email: email, phone: phoneNo, service: service, message: message, timestamp: serverTimestamp() }).then(() => {
                setLoading(false);
                window.location.reload(false);
                setResponseMessage("Form submitted successfully!");
            }).catch((error) => {
                setLoading(false);
            })
        } catch (error) {
            setLoading(false);
        }

    }

    return (
        <div className="form-area">
            <div className="scroll-down-svg">
                <img src={scrollDown} alt="scroll down" className="scroll-down" />
            </div>
            <Row className="contact-details-row">
                <Col xs={12} sm={12} md={12} lg={7} xl={7}></Col>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className="contact-details-row-right-col">
                    <div className="div-details">
                        <h4 className="details-header">About our locations</h4>
                        <p className="para mt-3">Our head office is based in Mumbai<br></br> with teams present all over India<br></br> including Delhi, Pune, Bangalore.</p>
                    </div>
                    <div className="div-details mt-5">
                        <h4 className="details-header">Social Presence</h4>
                        <div className="ham-social">
                            <a target="_blank" href="https://instagram.com/pastperfectlive?utm_medium=copy_link"><img src={instagramLogo} className="social-icon" /></a>
                            <a target="_blank" href="https://m.facebook.com/PastPerfect2016"><img src={fbLogo} className="social-icon" /></a>
                            <a target="_blank" href="https://twitter.com/PastPerfectLive?t=sSV39Z9J7Yb3RtD-mSBdQA&s=08"><img src={twitterLogo} className="social-icon" /></a>
                        </div>
                    </div>
                    <div className="div-details mt-5">
                        <h4 className="details-header">Email</h4>
                        <a href="mailto:info@pastperfect.in" className="text-d" style={{ color: "black" }}><p className="para mt-3 edit-btn-2">info@pastperfect.in</p></a>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={1} xl={1}></Col>
            </Row>
            <Row className="contact-form-row">
                <Container>
                    <Col xs={12} sm={12} md={1} lg={1} xl={1} ></Col>
                    <Col xs={12} sm={12} md={8} lg={6} xl={6} className="form-col">
                        <p style={{ color: 'green' }}>{responseMessage}</p>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group >
                                <Form.Control required type="text" placeholder="Your name*" onChange={e => setName(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mt-5 mb-4">
                                <Form.Control required type="email" placeholder="Email*" onChange={e => setEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mt-5 mb-4">
                                <Form.Control required type="phone" placeholder="Contact Number*" onChange={e => setPhoneNo(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mt-5 mb-4">
                                <Form.Control as="select" onChange={e => setService(e.target.value)}>
                                    <option value={"Archiving & Documentation"}>Archiving & Documentation</option>
                                    <option value={"Research Support"}>Research Support</option>
                                    <option value={"Curatorial Services"}>Curatorial Services</option>
                                </Form.Control>
                                <Form.Text className="text-muted">
                                    Select a service to enquire for
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mt-4 mb-4">
                                <Form.Control required as="textarea" rows={5} type="text" placeholder="Message" id="des" onChange={e => setMessage(e.target.value)} />
                            </Form.Group>
                            {/* <Form.Group>
                                <Form.Check type="checkbox" label="I accept all terms and condition." />
                            </Form.Group> */}
                            <Button className="box-btn mt-5" type="submit">
                                {loading ? <LoadingSpinner /> : "Submit"}
                            </Button>

                        </Form>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={4} xl={4}></Col>
                </Container>
            </Row>
        </div>
    )
}

export default CForm
