import { React, useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../../Global/LoadingSpinner"
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../../../firebase";




const Login = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState();

    const history = useHistory()


    const handleSubmit = async e => {
        e.preventDefault();

        setLoading(true);

        try {
            const user = await signInWithEmailAndPassword(auth, email, password)
            if (user) {
                setLoading(false);
                history.replace('/dashboard');
            } else {
                setLoading(false);
                setMessage("An error occured! Please try again!");
            }
        } catch (error) {
            setLoading(false);
            setMessage("An error occured! Please try again!");
        }
    }




    // useEffect(() => {
    //     if (setAuthToken) {
    //         history.push('/dashboard');
    //     }
    // }, [setAuthToken, history])

    return (
        <>
            <h1 className="big-header">Login</h1>
            <Form className="mt-5" onSubmit={handleSubmit}>

                <Form.Group className="mb-4">
                    <p style={{ color: 'red', textAlign: 'center' }}>{message}</p>
                </Form.Group>

                <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Control required type="email" onChange={e => setEmail(e.target.value)} className="auth-input" placeholder="Enter email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control required type="password" onChange={e => setPassword(e.target.value)} className="auth-input" placeholder="Password" />
                </Form.Group>
                <Row className="button-row mt-5">
                    <Col className="button-col">
                        <Button className="auth-button-primary" type="submit">
                            {loading ? <LoadingSpinner /> : "Login"}
                        </Button>
                    </Col>

                </Row>

            </Form>
        </>
    )
}


export default Login


