import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap';
import './AddTeam.scss'
import Profile from '../../../Global/Profile/profile'
import {
    BrowserRouter as Router,
    useParams,
    Link,
    useHistory
} from "react-router-dom";
import axios from 'axios'
import LoadingSpinner from '../../../Global/LoadingSpinner';


async function addTeamMember(data) {
    const url = 'https://pastperfect-backend.herokuapp.com/v1/team';
    const headers = {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };
    return axios.post(url, data, { headers }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error;
    });
}

async function editTeamText(data, id) {
    const url = `https://pastperfect-backend.herokuapp.com/v1/team/${id}`;
    const headers = {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };

    return axios.put(url, data, { headers }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error;
    });

}

async function editTeamImage(data, id) {
    const url = `https://pastperfect-backend.herokuapp.com/v1/team/image/${id}`;
    const headers = {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };

    return axios.put(url, data, { headers }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error;
    });

}


export default function AddTeam() {


    const { id } = useParams();

    const [img, setImg] = useState();
    const [imgPath, setImgPath] = useState();
    const [imgUrl, setImgUrl] = useState();
    const [modal, setModal] = useState(false);
    const [name, setName] = useState();
    const [bio, setBio] = useState();
    const [designation, setDesignation] = useState();

    const [loading, setLoading] = useState(false);

    const history = useHistory();


    useEffect(() => {
        if (id) {
            axios.get(`https://pastperfect-backend.herokuapp.com/v1/team/${id}`).then(function (response) {

                if (response.status === 200) {
                    // setWork({
                    //     img: response.data.img,
                    //     img_url: response.data.img_url,
                    //     title: response.data.title,
                    //     description: response.data.description,
                    //     client_name: response.data.client_name
                    // }
                    setName(response.data.name);
                    setImgPath(response.data.img);
                    setImgUrl(response.data.img_url)
                    setDesignation(response.data.designation);
                    setBio(response.data.bio);
                } else {
                    history.replace('/dashboard/team')
                }

            }
            )
        }
    }, [])

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData();
        data.append("img", img);
        data.set('name', name);
        data.set('bio', bio);
        data.set('designation', designation);

        const response = await addTeamMember(data);
        setLoading(false);
        if (response.status === 200) {
            history.replace('/dashboard/team');

        }
    }

    const handleEditText = async e => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData();
        // data.append("img", img);
        data.set('name', name);
        data.set('bio', bio);
        data.set('designation', designation);
        // data.set('img_path', imgPath);

        const response = await editTeamText(data, id);
        setLoading(false);
        if (response.status === 200) {
            history.replace('/dashboard/team');

        }
    }
    const handleEditImage = async e => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData();
        data.append("img", img);
        // data.set('title', title);
        // data.set('description', description);
        // data.set('client_name', clientName);

        const response = await editTeamImage(data, id);
        if (response.status === 200) {
            setModal(false);
            setImgUrl(response.data.img_url)
        }
        setLoading(false);
    }



    return (
        <div>
            <div className="pro-row">
                <Link to='/dashboard/team'><i className="bi bi-chevron-left">back</i></Link>
                <div className="desktop-only"> <Profile /></div>
            </div>
            <Row className="margin-t">
                <Container>
                    <h1 className="dashboard-header">{id ? "Edit Team Member" : "Add Team Member"}</h1>
                    <p className="para mt-1">{id ? "Update your Team Member Profile" : "Add a person to the valuable team of Past Perfect."}</p>

                    <Row className="margin-dash-4">
                        <Form onSubmit={id ? handleEditText : handleSubmit}>
                            <Row>
                                <Col xl={5} lg={12} cm={12} md={12} xs={12} sm={12}>
                                    <Form.Group>
                                        <Form.Label>Name of the Person*</Form.Label>
                                        <Form.Control required type="text" value={name || ''} placeholder="Enter the name" onChange={e => setName(e.target.value)} name="name" id="name" />
                                    </Form.Group>
                                    <Form.Group className="mt-4 mb-4">
                                        <Form.Label>Designation</Form.Label>
                                        <Form.Control required type="text" value={designation || ''} placeholder="Designation of person" onChange={e => setDesignation(e.target.value)} name="designation" id="designation" />
                                    </Form.Group>
                                    <Form.Group className="mt-4 mb-4">
                                        <Form.Label>Bio</Form.Label>
                                        <Form.Control required type="text" value={bio || ''} placeholder="Short Bio describing the person" onChange={e => setBio(e.target.value)} name="bio" id="bio" />
                                    </Form.Group>

                                </Col>
                                <Col xl={1} lg={12} cm={12} md={12} xs={12} sm={12}></Col>
                                <Col xl={5} lg={12} cm={12} md={12} xs={12} sm={12} className="img-col">
                                    {id ? <><p className="para">Existing Image:</p><img src={imgUrl} height="200" style={{ marginBottom: "20px" }} /><br /><Button className="submit-btn-small" onClick={() => setModal(true)}>Edit Image</Button></> : ""}
                                    {id ? "" : <> <p className="para">Insert a picture of the work</p><input type="file" className="img-fluid add-work-img" required onChange={e => setImg(e.target.files[0])} name="img" /></>}
                                </Col>
                                <Button className="submit-btn" type="submit">
                                    {loading ? <LoadingSpinner /> : "Submit"}
                                </Button>
                            </Row>
                        </Form>
                    </Row>
                </Container>
            </Row>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modal}
                onHide={() => setModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditImage}>
                        <p className="para">insert a new image:</p>
                        <input type="file" className="img-fluid add-work-img" required onChange={e => setImg(e.target.files[0])} name="img" />
                        <Button className="submit-btn" type="submit">
                            {loading ? "Updating..." : "Submit"}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

