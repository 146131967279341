export const worksReducer = (state = [], action) => {
    switch (action.type) {
        case "GET_WORKS":
            return state;
        case "SET_WORKS":
            state = action.payload
            return state
        default:
            return state;
    }
}


export const workReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_WORK":
            state = action.payload
            return state;
        default:
            return state;
    }
}