import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { React, useState } from "react";
import HomePage from './Components/ClientSide/Screens/HomePage/Homepage.js'
import Features from './Components/ClientSide/Screens/Features/Features.js'
import AboutUs from './Components/ClientSide/Screens/AboutUs/Aboutus.js'
import OurWorks from './Components/ClientSide/Screens/OurWorks/Ourworks.js'
import OurVerticals from './Components/ClientSide/Screens/OurVerticals/Ourverticals.js'
import ContactUs from './Components/ClientSide/Screens/ContactUs/Contactus.js'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ErrorPage from './404.js';
import AdminHome from './Components/AdminSide/Screens/Home.js'
import Hamburger from './Components/ClientSide/Global/Hamburger/hamburger.js'
import Auth from './Components/AdminSide/Screens/authentication/Auth';



// import dashboard from './Components/AdminSide/Screens/dashboard/dashboard.js'
// import enquiries from './Components/AdminSide/Screens/enquiries/enquiries.js'
// import works from './Components/AdminSide/Screens/works/works.js'
// import features from './Components/AdminSide/Screens/features/features.js'

// const routes = [
//   {
//     path: "/dashboard",
//    component: "dashboard"
//   },
//   {
//     path: "/enquiries",
//     component:"enquiries"
//   }

// ];

function Content() {

  return (
    <Router>
      <>

        <Switch>
          <Route exact path="/" component={HomePage} />

          <Route path="/auth" component={Auth} />
          <Route path="/dashboard" component={AdminHome} />
          <Route exact path="/hamburger" component={Hamburger} />
          <Route exact path="/our-works" component={OurWorks} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/features" component={Features} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/our-verticals" component={OurVerticals} />
          <Route path="/dashboard" component={AdminHome} ></Route>
          <Route path="" component={ErrorPage} ></Route>
        </Switch>
      </>
    </Router>
  );
}

export default Content;