import React, { useState, useEffect } from 'react'
import axios from 'axios';
import LoadingSpinner from '../LoadingSpinner';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useLocation,
    useRouteMatch
} from "react-router-dom";

import { Row, Col, Container, Button, Nav, Navbar } from 'react-bootstrap'
import { auth } from '../../../../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';


// async function logoutUser() {

//     // Only when using cookies

//     //     const url = '/logout';
//     //     const headers = {
//     //         'X-CSRF-TOKEN': getCookie('csrf_access_token'),
//     //     }

//     //     return axios.post(url, {}, { headers }).then(function (response) {
//     //         return response;
//     //     }).catch(function (error) {
//     //         return error;
//     //     });

//     localStorage.removeItem('jwt');
// }

const LogoutUser = () => {




    const history = useHistory();
    const location = useLocation();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const access_token = localStorage.getItem('jwt');
    const { path, url } = useRouteMatch();
    const [user, setUser] = useState({});
    const [expired, setExpired] = useState(true);

    onAuthStateChanged(auth, (currentUser) => [
        setUser(currentUser)
    ])

    const logout = async () => {
        await signOut(auth)
        history.replace('/auth/login');
    }



    useEffect(() => {
        if (!user) {
            logout();
        }
    }, [user])


    return (
        <div className="logout-space">
            <Button onClick={logout} className="logout-btn">{loading ? <LoadingSpinner /> : "Log Out"}</Button>
        </div>
    )
}

export default LogoutUser
