import React, { useState, useEffect } from 'react'
import './Feature.scss'
import { Container, Row, Col, Card, Button, Offcanvas } from 'react-bootstrap'
import axios from 'axios'
import FaImg from '../../../../Global/Images/FaImg.png'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { collection, getDocs, query } from 'firebase/firestore'
import { db } from '../../../../../../firebase'




const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    items: 3,
    navText: ["<i class='bi bi-arrow-left'></i>", "<i class='bi bi-arrow-right'></i>"],
    smartSpeed: 1000,
    autoWidth: false,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 3,

        }
    },
};


function Feature() {

    let [features, setFeatures] = useState([]);
    const [source, setSource] = useState();
    const [title, setTitle] = useState();
    const [date, setDate] = useState();
    const [description, setDescription] = useState();
    const [img, setImg] = useState();
    const [link, setLink] = useState();
    useEffect(() => {
        // axios.get('https://pastperfect-backend.herokuapp.com/v1/features').then(function (response) {
        //     setFeatures(response.data)
        // })
        let tFeatures = []
        try {
            (async () => {
                const featureRef = query(collection(db, "feature"));
                const featureSnap = await getDocs(featureRef);

                featureSnap.forEach((doc) => {
                    tFeatures.push({
                        doc_id: doc.id,
                        data: doc.data()
                    })
                })

                setFeatures(tFeatures)

            })();
        } catch (error) {

        }
    }, []);


    // const allFeatures = [
    //     {
    //         "source": "The Better India",
    //         "date": "June 13, 2020​",
    //         "title": "Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link": "https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img": "https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     },
    //     {
    //         "source": "The Better India",
    //         "date": "June 13, 2020​",
    //         "title": "Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link": "https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img": "https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     },
    //     {
    //         "source": "The Better India",
    //         "date": "June 13, 2020​",
    //         "title": "Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link": "https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img": "https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     },
    //     {
    //         "source": "The Better India",
    //         "date": "June 13, 2020​",
    //         "title": "Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link": "https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img": "https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     }
    // ]


    const [show, setShow] = useState(false)

    const handleClickOn = (source, date, title, description, img, link) => {
        setSource(source);
        setDate(date);
        setTitle(title);
        setDescription(description)
        setImg(img);
        setLink(link);
        setShow(true);
    }

    const handleClickOff = () => {
        // <WorkOverlay /> 
        setShow(false);
    }

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
            document.body.style.opacity = '1';
        } else {
            document.body.style.overflow = 'unset';
            document.body.style.opacity = '1';
        }
    }, [show]);

    return (
        <div className="feature-page-area">
            <Container>
                <Row className="fa-article">
                    {features.slice(0, 1).map((firstFeature, index) => (
                        <>
                            <Col lg={6} xl={6} sm={12} xs={12} md={5}>
                                <img src={firstFeature.data.imgUrl} className="img-fluid" alt="featured artcile of pastperfect"></img>
                            </Col>
                            <Col lg={1} xl={1} sm={12} xs={1} md={1}></Col>
                            <Col lg={5} xl={5} sm={12} xs={12} md={6} className="fa-info-col">
                                <p className="source-date"><strong>{firstFeature.data.source}</strong> | <span className="date">{firstFeature.data.date}</span></p>
                                <h3 className="fa-card-header">{firstFeature.data.title}</h3>
                                <Button onClick={() => handleClickOn(firstFeature.data.source, firstFeature.data.date, firstFeature.data.title, firstFeature.data.description, firstFeature.data.img_url, firstFeature.data.link)} className="edit-btn-2 mt-5">Learn More</Button>
                            </Col>
                        </>
                    ))}

                </Row>
                <Row className="ver-card-row mb-5">
                    {/* <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <Card className="feature-card mb-4 p-3">
                                <Card.Body>
                                <div className="mt-3 mb-2"><span className="source">The Better India </span> | <span className="date">June 13, 2020</span></div>
                                    <Card.Title className="card-title-dash mt-4 mb-4">Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History</Card.Title>
                                    <a href="#" className="edit-btn-2">Read More</a>
                                </Card.Body>
                            </Card>
                    </Col> */}
                    {features.slice(1,).map((feature, index) => (
                        <>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12} key={index}>
                                <Card className="feature-card mb-4 p-3">
                                    <Card.Body>
                                        <div className="mt-3 mb-2"><span className="source">{feature.data.source}</span> | <span className="date">{feature.data.date}</span></div>
                                        <Card.Title className="card-title-dash mt-3 mb-3">{feature.data.title}</Card.Title>
                                        <Button onClick={() => handleClickOn(feature.data.source, feature.data.date, feature.data.title, feature.data.description, feature.data.img_url, feature.data.link)} target="_blank" className="edit-btn-2">Read More</Button>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </>
                    ))}
                    {/* <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <button className="box-btn">Read More <i className="bi bi-search ml-3"></i></button>
                    </Col> */}
                </Row>
            </Container>
            {show && (<Offcanvas show={show} onHide={handleClickOff}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="work-overlay-padding">
                        <p className="work-overlay-title">{date} | <span style={{ fontWeight: "bold" }}>{source}</span></p>

                        <h2 className="work-overlay-client-name">{title}</h2>
                        <p className="feature-link">{link !== "undefined" ? <a href={link} style={{ textDecoration: "none" }} target="_blank">go to the article</a> : ""}</p>
                        {img ? <img src={img} alt="past perfect client work" className="work-overlay-img"></img> : ""}

                        <p className="work-overlay-description mb-5">{description}</p>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>)}
        </div>
    )
}

export default Feature
