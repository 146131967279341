import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Card, Button, Form, Modal } from 'react-bootstrap'
import Profile from '../../Global/Profile/profile.js'
import './works.scss'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import {

    Link,
    useHistory
} from "react-router-dom";
import axios from 'axios'
import { db, storage } from '../../../../firebase.js';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';

const sortOrdersRef = collection(db, 'sortOrders');

async function deleteWork(id) {
    const url = `https://pastperfect-backend.herokuapp.com/v1/portfolio/${id}`;
    const headers = {
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };
    return axios.delete(url, { headers }).then(function (response) {
        window.location.reload(false);
        return response;
    }).catch(function (error) {
        return error;
    })
}

async function setWorkPriority(id, priority) {
    const url = `https://pastperfect-backend.herokuapp.com/v1/portfolio/priority/${priority}`;
    const headers = {
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    }

    return axios.put(url, { id: id }, { headers }).then(function (response) {
        return response
    }).catch(function (error) {
        return error;
    })
}

async function sortWorks(data) {
    const url = 'https://pastperfect-backend.herokuapp.com/v1/portfolio/sort';
    const headers = {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };
    return axios.put(url, data, { headers }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error;
    });
}


export default function Works() {
    const [works, setWorks] = useState([]);

    const [sortModal, setSortModal] = useState(false);

    const [sortingChanged, setSortingChanged] = useState(false);

    const [workOne, setWorkOne] = useState();
    const [workTwo, setWorkTwo] = useState();
    const [workThree, setWorkThree] = useState();
    const history = useHistory();


    const handleDelete = async (e, id, imgName, imgType, addImages) => {
        e.preventDefault();

        try {

            if (imgName.trim().length > 10) {
                let path = `images-v2/works/${imgName + "." + imgType}`
                const imageRef = ref(storage, path);

                deleteObject(imageRef).then(async () => {
                    for (let i = 0; i < addImages.length; i++) {
                        path = `images-v2/works/${addImages[i].imgName + "." + addImages[i].imgType}`
                        const addImageRef = ref(storage, path);
                        deleteObject(addImageRef).then(async () => {
                        })
                    }
                    await deleteDoc(doc(db, "work", id)).then(() => window.location.reload(false))
                })
            }
        } catch (error) {
        }
    }


    const handleWorkPriority = async (e, id, priority) => {
        e.preventDefault();



        if (priority === 1) {
            try {
                await setDoc(doc(db, "priority", "1"), {
                    workId: id,
                }).then(() => setWorkOne(id))
            } catch (error) {

            }
            // const response = await setWorkPriority(id, priority)
            // if (response.status === 200) {

            //     // for(let i=0; i<works.length; i++){
            //     //     if (works[i].id === id){
            //     //         setWorkOne
            //     //     }
            //     // }

            //     setWorkOne(id);
            //     // console.log(workOne)
            // }
        } else if (priority === 2) {
            try {
                await setDoc(doc(db, "priority", "2"), {
                    workId: id,
                }).then(() => setWorkTwo(id))
            } catch (error) {

            }
            // const response = await setWorkPriority(id, priority)
            // if (response.status === 200) {
            //     setWorkTwo(id);
            //     // console.log(workTwo)
            // }
        } else if (priority === 3) {
            try {
                await setDoc(doc(db, "priority", "3"), {
                    workId: id,
                }).then(() => setWorkThree(id))
            } catch (error) {
                console.log(error)
            }
            // const response = await setWorkPriority(id, priority)
            // if (response.status === 200) {
            //     setWorkThree(id)
            //     // console.log(workThree)
            // }
        }
    }

    const handleSortEnd = ({ oldIndex, newIndex }) => {
        setWorks(arrayMoveImmutable(works, oldIndex, newIndex));
        setSortingChanged(true);
    };

    const handleSortSubmit = async (e) => {
        e.preventDefault();

        try {
            // Update the sorting order in the work collection directly
            await Promise.all(
                works.map((work, index) => {
                    const workDocRef = doc(db, 'work', work.doc_id);
                    return updateDoc(workDocRef, { index: index });
                })
            );

            setSortModal(false);
            setSortingChanged(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // Retrieve works data from Firebase and sort by the index field
        const fetchWorks = async () => {
            let fetchedWorks = [];
            try {
                const workSnap = await getDocs(collection(db, "work"));

                workSnap.forEach((doc) => {
                    fetchedWorks.push({
                        doc_id: doc.id,
                        data: doc.data()
                    });
                });

                fetchedWorks.sort((a, b) => a.data.index - b.data.index);
                setWorks(fetchedWorks);
            } catch (error) {
                console.log(error);
            }
        };
        fetchWorks();
    }, []);


    const RowHandler = SortableHandle(() => <div style={{ cursor: "grab", color: "#452610" }}><i class="bi bi-grip-horizontal"></i></div>);

    const SortableItem = SortableElement(({ work }) => (
        <Card className="sortingCard">
            <Card.Body className="sortingCard-body">
                <p>{work.data.clientName}</p>
                <RowHandler />
            </Card.Body>
        </Card>
    ));

    const SortableList = SortableContainer(({ works }) => (
        <div>
            {works.map((work, index) => (
                <SortableItem key={work.doc_id} index={index} work={work} />
            ))}
        </div>
    ))


    // const allWorks = [
    //     {
    //         "title": "The Willingdon Sports Club",
    //         "des": "We began work on cataloguing historical material of the Willingdon Sports Club  in preparation for its centenary year in 2017. Our team spent months pouring over voluminous minute books, detailed correspondence and striking photographs and memorabilia that captured layers of the Club's history and evolution.  The understanding developed as a result of the documentation process and insights from having a familiarity with records eventually put us in a position to assist the writers of the Centenary publication.     Through additional external research and further contextualization, the team, along with the Club's Centenary Committee, has been able to develop storylines and narratives that highlight the rich history of the Willingdon Sports Club.",
    //         "img": "https://static.wixstatic.com/media/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.jpeg/v1/fill/w_568,h_367,al_c,q_80,usm_0.66_1.00_0.01/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.webp",
    //     },
    //     {
    //         "title": "The Willingdon Sports Club",
    //         "des": "We began work on cataloguing historical material of the Willingdon Sports Club  in preparation for its centenary year in 2017. Our team spent months pouring over voluminous minute books, detailed correspondence and striking photographs and memorabilia that captured layers of the Club's history and evolution.  The understanding developed as a result of the documentation process and insights from having a familiarity with records eventually put us in a position to assist the writers of the Centenary publication.     Through additional external research and further contextualization, the team, along with the Club's Centenary Committee, has been able to develop storylines and narratives that highlight the rich history of the Willingdon Sports Club.",
    //         "img": "https://static.wixstatic.com/media/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.jpeg/v1/fill/w_568,h_367,al_c,q_80,usm_0.66_1.00_0.01/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.webp",
    //     },
    //     {
    //         "title": "The Willingdon Sports Club",
    //         "des": "We began work on cataloguing historical material of the Willingdon Sports Club  in preparation for its centenary year in 2017. Our team spent months pouring over voluminous minute books, detailed correspondence and striking photographs and memorabilia that captured layers of the Club's history and evolution.  The understanding developed as a result of the documentation process and insights from having a familiarity with records eventually put us in a position to assist the writers of the Centenary publication.     Through additional external research and further contextualization, the team, along with the Club's Centenary Committee, has been able to develop storylines and narratives that highlight the rich history of the Willingdon Sports Club.",
    //         "img": "https://static.wixstatic.com/media/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.jpeg/v1/fill/w_568,h_367,al_c,q_80,usm_0.66_1.00_0.01/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.webp",
    //     },
    //     {
    //         "title": "The Willingdon Sports Club",
    //         "des": "We began work on cataloguing historical material of the Willingdon Sports Club  in preparation for its centenary year in 2017. Our team spent months pouring over voluminous minute books, detailed correspondence and striking photographs and memorabilia that captured layers of the Club's history and evolution.  The understanding developed as a result of the documentation process and insights from having a familiarity with records eventually put us in a position to assist the writers of the Centenary publication.     Through additional external research and further contextualization, the team, along with the Club's Centenary Committee, has been able to develop storylines and narratives that highlight the rich history of the Willingdon Sports Club.",
    //         "img": "https://static.wixstatic.com/media/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.jpeg/v1/fill/w_568,h_367,al_c,q_80,usm_0.66_1.00_0.01/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.webp",
    //     },
    //     {
    //         "title": "The Willingdon Sports Club",
    //         "des": "We began work on cataloguing historical material of the Willingdon Sports Club  in preparation for its centenary year in 2017. Our team spent months pouring over voluminous minute books, detailed correspondence and striking photographs and memorabilia that captured layers of the Club's history and evolution.  The understanding developed as a result of the documentation process and insights from having a familiarity with records eventually put us in a position to assist the writers of the Centenary publication.     Through additional external research and further contextualization, the team, along with the Club's Centenary Committee, has been able to develop storylines and narratives that highlight the rich history of the Willingdon Sports Club.",
    //         "img": "https://static.wixstatic.com/media/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.jpeg/v1/fill/w_568,h_367,al_c,q_80,usm_0.66_1.00_0.01/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.webp",
    //     },
    //     {
    //         "title": "The Willingdon Sports Club",
    //         "des": "We began work on cataloguing historical material of the Willingdon Sports Club  in preparation for its centenary year in 2017. Our team spent months pouring over voluminous minute books, detailed correspondence and striking photographs and memorabilia that captured layers of the Club's history and evolution.  The understanding developed as a result of the documentation process and insights from having a familiarity with records eventually put us in a position to assist the writers of the Centenary publication.     Through additional external research and further contextualization, the team, along with the Club's Centenary Committee, has been able to develop storylines and narratives that highlight the rich history of the Willingdon Sports Club.",
    //         "img": "https://static.wixstatic.com/media/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.jpeg/v1/fill/w_568,h_367,al_c,q_80,usm_0.66_1.00_0.01/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.webp",
    //     },
    //     {
    //         "title": "The Willingdon Sports Club",
    //         "des": "We began work on cataloguing historical material of the Willingdon Sports Club  in preparation for its centenary year in 2017. Our team spent months pouring over voluminous minute books, detailed correspondence and striking photographs and memorabilia that captured layers of the Club's history and evolution.  The understanding developed as a result of the documentation process and insights from having a familiarity with records eventually put us in a position to assist the writers of the Centenary publication.     Through additional external research and further contextualization, the team, along with the Club's Centenary Committee, has been able to develop storylines and narratives that highlight the rich history of the Willingdon Sports Club.",
    //         "img": "https://static.wixstatic.com/media/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.jpeg/v1/fill/w_568,h_367,al_c,q_80,usm_0.66_1.00_0.01/b5bff8_244aa38c79654275afd1f045a2e70c9f~mv2.webp",
    //     }
    // ]
    async function migrateSortOrder() {
        try {
            // Fetch all documents from sortOrder collection
            const sortOrderSnap = await getDocs(collection(db, 'sortOrders'));

            // For each sortOrder document, update the respective work document with the index value
            const promises = sortOrderSnap.docs.map(async sortOrderDoc => {
                const index = sortOrderDoc.data().index;
                const workDocRef = doc(db, 'work', sortOrderDoc.id);

                // Check if work document exists before updating
                const workDocSnap = await getDoc(workDocRef);
                if (!workDocSnap.exists) {
                    console.warn(`Work document with ID ${sortOrderDoc.id} does not exist. Skipping.`);
                    return;
                }

                return updateDoc(workDocRef, { index });
            });

            await Promise.all(promises);
            alert("Migration successful!");
        } catch (error) {
            console.error("Error during migration:", error);
            alert("Migration failed. Check the console for details.");
        }
    }

    return (
        <div>
            {/* <WorkModal
                show={modal}
                onHide={() => setModal(false)}
            /> */}

            <Row>
                <div className="desktop-only"> <Profile /></div>
            </Row>
            <Container>
                <Row className="margin-dash row-margin">

                    <h1 className="dashboard-header">Works</h1>
                    <Row>
                        <Col lg={6} xl={8} md={12}>
                            <p className="para mt-1">Create, View, Update, Delete your Work. Over here</p>
                        </Col>
                        <Col lg={2} xl={2} md={6} sm={4} className="export">
                            <Link to='/dashboard/addWork' className="text-d">
                                <Button className="add-work-btn">Add Work</Button>
                            </Link>
                            {/* <Button onClick={migrateSortOrder}>Migrate Sort Orders</Button> */}

                        </Col>
                        <Col lg={3} xl={2} md={6} sm={4} className="export">
                            <Button className="sort-work-btn" onClick={() => setSortModal(true)}>Sort Works</Button>
                        </Col>
                    </Row>

                    <Row className="work-card-deck">

                        {(works.length != 0) ? <div className="fa-jumbotron">
                            <h1 className="admin-works-page-sub-header">Select top 3 featured works.</h1>
                            <Row className="fa-card-row">
                                <Col lg={4} xl={4} md={12} sm={4} xs={12}>
                                    <Form.Group className="mt-5 mb-4 fa-work-card-jumbo">
                                        <Form.Control value={workOne || ''} as="select" onChange={e => handleWorkPriority(e, e.target.value, 1)}>
                                            {works.map((work, index) => (
                                                <option value={work.doc_id} key={index}>{work.data.clientName}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                            Work 1
                                        </Form.Text>
                                    </Form.Group>

                                </Col>
                                <Col lg={4} xl={4} md={12} sm={4} xs={12}>
                                    <Form.Group className="mt-5 mb-4 fa-work-card-jumbo">
                                        <Form.Control value={workTwo || ''} as="select" onChange={e => handleWorkPriority(e, e.target.value, 2)}>
                                            {works.map((work, index) => (
                                                <option value={work.doc_id} key={index}>{work.data.clientName}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                            Work 2
                                        </Form.Text>
                                    </Form.Group>

                                </Col>
                                <Col lg={4} xl={4} md={12} sm={4} xs={12}>
                                    <Form.Group className="mt-5 mb-4 fa-work-card-jumbo">
                                        <Form.Control value={workThree || ''} as="select" onChange={e => handleWorkPriority(e, e.target.value, 3)}>
                                            {works.map((work, index) => (
                                                <option value={work.doc_id} key={index}>{work.data.clientName}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                            Work 3
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>

                        </div> : ""}




                        {works.map((work, index) => (
                            <Col lg={6} xl={4} md={12} sm={6} xs={12} key={index}>
                                <Card className="work-card">
                                    <Card.Img variant="top" src={work.data.imgUrl} className="work-img" />
                                    <Card.Body>
                                        <Card.Title className="card-title-dash">{work.data.title}</Card.Title>
                                        <div className="btn-grp-2">
                                            <Link to={`/dashboard/editwork/${work.doc_id}`} className="edit-btn-1">Edit</Link>
                                            <Button style={{ backgroundColor: 'unset', border: 'unset' }} onClick={e => handleDelete(e, work.doc_id, work.data.imgName, work.data.imgType, work.data.addImages)}><i className="bi bi-trash"></i></Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Row>
            </Container>

            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={sortModal}
                onHide={() => setSortModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Arrange your works
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SortableList works={works} onSortEnd={handleSortEnd} axis="y" lockAxis="y" helperClass="sortableHelper" useDragHandle={true} />
                </Modal.Body>
                <Modal.Footer>
                    {sortingChanged ? <Button className="save-sorting-btn" onClick={handleSortSubmit}>Save</Button> : <Button className="save-sorting-btn" onClick={handleSortSubmit} disabled>no changes made</Button>}
                </Modal.Footer>
            </Modal>

        </div>
    )
}
