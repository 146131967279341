import React, { useEffect, useState, useCallback } from 'react'
import { Row, Col, Container, Navbar, Button } from 'react-bootstrap'
import about_hero from '../../../../Global/Images/about-hero.jpg'
import Founders from '../../../../Global/Images/founders.jpg'
import './Hero.scss'
import AOS from "aos";
import "aos/dist/aos.css";
import axios from 'axios'

function Hero() {

    const [teamMembers, setTeamMembers] = useState([]);
    const [name, setName] = useState();
    const [bio, setBio] = useState();
    const [img, setImg] = useState();
    const [designation, setDesignation] = useState();
    // useEffect(() => {
    //     axios.get('https://pastperfect-backend.herokuapp.com/v1/team').then(function (response) {
    //         setTeamMembers(response.data)
    //     })
    // }, []);


    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    // const staff = [
    //     {
    //         name: 'Sanghamitra Chatterjee',
    //         designation: 'Founder',
    //         img: teamImg,
    //         bio: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    //     },
    //     {
    //         name: 'Deepti Anand',
    //         designation: 'Co-Founder',
    //         img: teamImg,
    //         bio: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    //     },
    //     {
    //         name: 'Trisha George',
    //         designation: 'Senior Researcher',
    //         img: teamImg,
    //         bio: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    //     },
    //     {
    //         name: 'Vasudha Deshmukh',
    //         designation: 'Research Associate',
    //         img: teamImg,
    //         bio: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    //     },
    //     {
    //         name: 'Kevin Tony',
    //         designation: 'Jr. Research Associate',
    //         img: teamImg,
    //         bio: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    //     },
    //     {
    //         name: 'Sneha Rego',
    //         designation: 'Archivist',
    //         img: teamImg,
    //         bio: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    //     },
    // ]

    return (
        <div className="about-area">
            <Container>
                <Row>
                    <Col lg={5} md={5} sm={12} xs={12} xl={5}>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} xl={6}>
                        <h1 className="big-header" id="big-header-about">About <br></br>Past Perfect</h1>

                    </Col>

                </Row>
                <Row className="sec-row-about">
                    <Col lg={1} md={1} sm={12} xs={12} xl={1}></Col>
                    <Col lg={5} md={5} sm={12} xs={12} xl={5}>
                        <img data-aos="fade-up" src={about_hero} alt="PastPerfect" className="img-fluid sec-row-img" />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} xl={6}>
                    </Col>
                </Row>

                <Row className="third-row-about">
                    <Col lg={4} md={4} sm={12} xs={12} xl={4}></Col>
                    <Col lg={7} md={7} sm={12} xs={12} xl={7} className="about-past">
                        <h2 className="about-header">Who Are We?</h2>
                        <p className="about-para mt-5">
                            Past Perfect Heritage Management was founded in 2016, with the intention of taking the art of archiving and research to a larger audience - the key motive being to preserve and promote heritage and inherited legacies, making them relevant for future generations.
                            <br></br><br></br>
                            As archivists, we help you in locating, identifying and documenting historically significant material that tells your story and develop repositories for the physical preservation of such material.
                            <br></br><br></br>
                            As researchers and content curators, our expertise lies in discovering and weaving together a narrative through stories, experiences, milestones and accomplishments that fuel the way people connect with their heritage and past.
                        </p>
                    </Col>
                    <Col lg={1} md={1} sm={12} xs={12} xl={1}>
                    </Col>
                </Row>
            </Container>

            <Row className="about-team">
                <Container>
                    <Row className="mt-5">
                        <Col xl={1} lg={1} md={1} sm={12} xs={12}></Col>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <h2 className="big-header">Meet Our Founders.</h2>
                            <div className="margin-m-t"></div>
                            <p className="para mt-5">
                                Sanghamitra Chatterjee and Deepti Anand started Past Perfect Heritage Management in April 2016 with a goal to make archiving and research services easily available.</p>


                        </Col>
                        {/* <Col xl={1} lg={1} md={1} sm={12} xs={12}></Col> */}
                        <Col xl={4} lg={4} md={4} sm={12} xs={12} className="team-info-col">
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={12} xs={12}></Col>
                    </Row>
                    {/* <Row className="about-team-row">
                        {teamMembers.map((member, i) => (
                            <Col lg={6} md={6} sm={12} xs={12} xl={6} key={i} className="about-team-col mb-3 ">
                                <Row>
                                    <Col lg={6} md={6} sm={12} xs={12} xl={6}>
                                        <img src={member.img_url} alt={member.name} className="about-team-img"></img>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} xl={6}>
                                        <div className="about-overlay">
                                            <p className="desig-text">{member.designation}</p>
                                            <p className="name-text">{member.name}</p>
                                            <p className="bio-text">{member.bio}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </Row> */}
                    <Row className="about-team-row">
                        <Col lg={4} md={12} sm={12} xs={12} xl={4} className="">
                            <div className="about-overlay">
                                <p className="desig-text">Founder</p>
                                <p className="name-text">Sanghamitra Chatterjee</p>
                                <p className="bio-text mt-4">Sanghamitra has several years’ experience in the field of business and corporate archiving. Her professional journey began with the 118-year-old Godrej Group, where she set-up the archive of one of India’s most trusted brands. She has also been an Advisor to the Cipla Archives, Mumbai where she introduced archival processes and documented the pharmaceutical company’s core archival collection. With a desire to explore other histories, and realizing the untapped potential of archiving in India, Sanghamitra decided to start Past Perfect in 2016.</p>
                            </div>
                        </Col>
                        <Col lg={4} md={12} sm={12} xs={12} xl={4} className="">
                            <img src={Founders} alt="team" className="img-fluid m-t-b founder-img"></img>
                        </Col>
                        <Col lg={4} md={12} sm={12} xs={12} xl={4} className="">
                            <div className="about-overlay">
                                <p className="desig-text">Co-founder</p>
                                <p className="name-text">Deepti Anand</p>
                                <p className="bio-text">Deepti started her archiving career at the Godrej Archives, where she imbibed the processes and functions of an archive. She then also took up independent research projects, which allowed her to gain deeper experience in analyzing primary material, designing research methodologies and joining the dots to build a larger story. She joined Sanghamitra in 2016 to start Past Perfect, with an ambitious drive to preserve and document micro or macro histories, and encourage engagement around it. </p>
                            </div>
                        </Col>

                    </Row>
                    <Row className="about-team-row text-center">
                        <p className="normal ">
                            Together, over the last few years, they have managed numerous projects across the country, along with having built a strong team of history professionals.</p>

                    </Row>
                </Container>
            </Row>
        </div>
    )
}

export default Hero
