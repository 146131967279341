import React from 'react'
import Navbar from '../../Global/navbar/navbar'
import Footer from '../../Global/footer/footer.js'
import Hero from './Components/Hero/Hero.js'
import Feature from './Components/Feature/Feature.js'

export default function Features() {
    return (
        <div className="feature-area">
            <Navbar />
            <Hero />
            <Feature/>
            <Footer />
        </div>
    )
}

