import { Row, Col, Container, Card, Button, Table } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch
} from "react-router-dom";
import axios from 'axios';
import Profile from '../../Global/Profile/profile';
import { useState, useEffect } from 'react';
import { deleteUser } from 'firebase/auth';
import { collection, deleteDoc, doc, getDocs, query } from 'firebase/firestore';
import { db } from '../../../../firebase';



const Users = () => {

    const [users, setUsers] = useState([])
    useEffect(() => {
        let tUsers = []
        try {
            (async () => {
                const userRef = query(collection(db, "user"));
                const userSnap = await getDocs(userRef);

                userSnap.forEach((doc) => {
                    tUsers.push({
                        doc_id: doc.id,
                        data: doc.data()
                    })
                })

                setUsers(tUsers)

            })();
        } catch (error) {

        }

    }, []);



    return (
        <>
            <Row>
                <div className="desktop-only"> <Profile /></div>
            </Row>
            <Container>
                <Row className="margin-dash row-margin">

                    <h1 className="dashboard-header">Users</h1>
                    <Row>
                        <Col lg={6} xl={8} md={12}>
                            <p className="para mt-1">view and add new users here</p>
                        </Col>
                        <Col lg={2} xl={2} md={12}></Col>
                        <Col lg={5} xl={2} md={12} className="export">
                            <Link to='/dashboard/addUser' className="text-d">
                                <Button className="add-work-btn">Add User</Button>
                            </Link>
                        </Col>
                    </Row>

                    <Row className="table">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>public-id</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.data.user_id}</td>
                                        <td>{user.data.name}</td>
                                        <td>{user.data.email}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                </Row>
            </Container>
        </>
    )
}

export default Users
