import React, { useEffect } from 'react'
import './ourVerticals.scss'
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Col, Row, Card, Button } from 'react-bootstrap'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Link,
    useLocation
} from "react-router-dom";


import instagramLogo from '../../../../Global/Images/hero_I.svg'
import fbLogo from '../../../../Global/Images/hero_F.svg'
import twitterLogo from '../../../../Global/Images/hero_I.svg'


function OurVerticals() {

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);
    return (
        <div className="vertical-area">
            <Container>

                <Row className="row-margin">
                    <Col xl={1} lg={1} md={12} sm={12} xs={12}></Col>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>

                        <div className="home-details-row-right-col">
                            <h2 className="big-header mb-5">Our<br></br> Verticals</h2>
                            <p className="para left-vertical-sec-para mt-3 w-75">Over the years, Past Perfect has grown to become a consultant and service provider for varied history and heritage inspired applications. Our team of archivists, researchers and curators are enthusiastic and fully equipped to dabble in subjects ranging across a wide spectrum - political, social, technological and the arts.</p>
                            <Link to="/our-verticals"><button className="box-btn mt-3">Learn More</button></Link>

                        </div>

                    </Col>
                    <Col xl={1} lg={1} md={12} sm={12} xs={12}></Col>
                    <Col xl={5} lg={5} md={12} sm={12} xs={12} className="info-col">
                        <div className="margin-m-t"></div>
                        <div className="connector" data-aos="fade-up">
                            <div className="connector-element">
                                <div className="connector-dot"></div>
                                <div className="connector-line"></div>
                            </div>
                            <div className="service-card">
                                <h3 className="normal">01 Archiving & Documentation</h3>
                                <p className="para mt-d">From building your collection to deep diving into your history, we help you identify and highlight your unique story. </p>
                                {/* <Link to="/our-verticals"><a className="edit-btn-2">Learn More</a></Link> */}
                            </div>
                        </div>
                        <div className="service connector" data-aos="fade-up">
                            <div className="connector-element">
                                <div className="connector-dot"></div>
                                <div className="connector-line"></div>
                            </div>
                            <div className="service-card">
                                <h3 className="normal">02 Research Support</h3>
                                <p className="para mt-d">No event has, and can ever be, studied in isolation. Over the years, Past Perfect has had the opportunity to conduct dedicated research assignments for academicians, publishers as well as film producers.</p>
                                {/* <Link to="/our-verticals"><a className="edit-btn-2">Learn More</a></Link> */}
                            </div>
                        </div>
                        <div className="service connector" data-aos="fade-up">
                            <div className="connector-element">
                                <div className="connector-dot"></div>
                                <div className="connector-line"></div>
                            </div>
                            <div className="service-card">
                                <h3 className="normal">03 Curatorial Services</h3>
                                <p className="para mt-d">There are varied ways to reimagine history and communicate legacies. For an institution, your archive can be repurposed for brand building, establishing a credibility or engaging your stakeholders.</p>
                                {/* <Link to="/our-verticals"><a className="edit-btn-2">Learn More</a></Link> */}
                            </div>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} xs={12}></Col>
                </Row>


                {/* <Container>
                <Row className="ver-card-row">
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Card className="home-vertical-card">
                         <Card.Img variant="top" src={tempImg} className="vertical-symbols" />
                            <Card.Body>
                                <Card.Title className="normal">
                                Card Title</Card.Title>
                                <Card.Text>
                                <p className="para">Some quick example text to build on the card title and make up the bulk of
                                the card's content.</p>
                                </Card.Text>
                                 <button className="box-btn">Learn More</button>
                            </Card.Body>
                            </Card>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Card className="home-vertical-card">
                         <Card.Img variant="top" src={tempImg} className="vertical-symbols" />
                            <Card.Body>
                                <Card.Title className="normal">
                                Card Title</Card.Title>
                                <Card.Text>
                                <p className="para">Some quick example text to build on the card title and make up the bulk of
                                the card's content.</p>
                                </Card.Text>
                                 <button className="box-btn">Learn More</button>
                            </Card.Body>
                            </Card>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Card className="home-vertical-card">
                         <Card.Img variant="top" src={tempImg} className="vertical-symbols" />
                            <Card.Body>
                                <Card.Title className="normal">
                                Card Title</Card.Title>
                                <Card.Text>
                                <p className="para">Some quick example text to build on the card title and make up the bulk of
                                the card's content.</p>
                                </Card.Text>
                                 <button className="box-btn">Learn More</button>
                            </Card.Body>
                            </Card>
                    </Col>
                </Row>     
                    </Container> */}
            </Container>
        </div>
    )
}

export default OurVerticals
