import React from 'react'
import './footer.scss'
import { Container, Col, Row, Button, Form } from 'react-bootstrap'
import logo from '../../../AdminSide/Global/images/logo.svg'
import instagramLogo from '../Images/I.svg'
import fbLogo from '../Images/F.svg'
import twitterLogo from '../Images/T.svg'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Link,
    useLocation
} from "react-router-dom";


function footer() {
    const navName = [
        {
            'name': 'Home',
            'to': "/"
        },
        {
            'name': 'Our Verticals',
            'to': "/our-verticals"
        },
        {
            'name': 'Our Work',
            'to': "/our-works"
        },
        {
            'name': 'About Us',
            'to': "/about-us"
        },
        {
            'name': 'Features',
            'to': "/features"
        },
        {
            'name': 'Contact Us',
            'to': "/contact-us"
        },
    ]
    return (
        <div className="footer-area">
            <Container>
                <Row>
                    <Col xl={2} lg={2} md={5} sm={12} xs={12} className="mobile-margin">
                        <img src={logo} className="img-fluid logo-foot" alt="past perfect logo"></img>
                        <div className="mt-5"></div>
                        <p className="para foot-para">Building bridges to the past with precision and passion 
<br></br><br></br>
Unraveling untold stories and complex histories in all its shades</p>
                    </Col>
                    <Col xl={2} lg={2} md={1} sm={12} xs={12}>
                    </Col>
                    <Col xl={2} lg={2} md={6} sm={12} xs={12} className="mobile-margin">
                        <h3 className="footer-sub-head">Useful<br></br> Links</h3>
                        <div className="mt-5"></div>
                        {navName.map((nav, index) => (
                            <Link key={index} className="text-d footer-link" to={nav.to}> <p className="para foot-para">{nav.name}</p></Link>
                        ))}
                        <a href="http://blog.pastperfect.co.in/" style={{textDecoration:"none"}} target="_blank"><p className="para foot-para">Blog</p></a>
                    </Col>
                    <Col xl={1} lg={1} md={0} sm={12} xs={12}>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12} className="mobile-margin">
                        <h3 className="footer-sub-head">Connect with<br></br> Past Perfect</h3>
                        <div className="ham-social mt-5">
                            <a target="_blank" href="https://instagram.com/pastperfectlive?utm_medium=copy_link"><img src={instagramLogo} className="social-icon" /></a>
                            <a target="_blank" href="https://m.facebook.com/PastPerfect2016"><img src={fbLogo} className="social-icon" /></a>
                            <a target="_blank" href="https://twitter.com/PastPerfectLive?t=sSV39Z9J7Yb3RtD-mSBdQA&s=08"><img src={twitterLogo} className="social-icon" /></a>
                        </div>
                        <div className="mt-5"></div>
                            {/* <Form>
                                <Form.Group>
                                    <Form.Control required type="text" placeholder="Suscribe for Newsletter!" />
                                </Form.Group>

                                <div className="mt-4"></div>
                                <button className="box-btn">Suscribe</button>
                            </Form> */}
                    </Col>
                    <Col xl={1} lg={1} md={12} sm={12} xs={12}>
                    </Col>
                </Row>
                <hr className="footer-hr" />
                <div className="bottom-foot">
                    <div className="para foot-para">© Past Perfect Heritage Management</div>
                    {/* <p className="para foot-para"><Link to="/terms-and-conditions" className="text-d para foot-para"> Term and Conditions</Link> | <Link className="text-d para foot-para" to="/privacy-policy"> Privacy Policy</Link></p> */}
                </div>
            </Container>
        </div>
    )
}

export default footer
