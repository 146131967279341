import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap'
import './addFeature.scss'
import Profile from '../../../Global/Profile/profile.js'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    Link,
    useHistory
} from "react-router-dom";
import { useForm } from 'react-hook-form'
import axios from 'axios'
import getCookie from '../../../functions'
import LoadingSpinner from '../../../Global/LoadingSpinner';
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { db, storage } from '../../../../../firebase';
import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { async } from '@firebase/util';

async function addFeature(data) {
    const url = 'https://pastperfect-backend.herokuapp.com/v1/features';
    const headers = {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };
    return axios.post(url, data, { headers }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error;
    });
}

function generateCode() {
    const array = new Uint8Array(8);
    window.crypto.getRandomValues(array);
    return Array.from(array, (byte) => ('0' + byte.toString(16)).slice(-2)).join('');
}

async function editFeatureText(data, id) {
    const url = `https://pastperfect-backend.herokuapp.com/v1/features/${id}`;
    const headers = {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };

    return axios.put(url, data, { headers }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error;
    });

}

async function editFeatureImage(data, id) {
    const url = `https://pastperfect-backend.herokuapp.com/v1/features/image/${id}`;
    const headers = {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };

    return axios.put(url, data, { headers }).then(function (response) {

        if (response.status === 200) {
            window.location.reload(false);
        }

        return response;
    }).catch(function (error) {
        return error;
    });

}


export default function AddFeature() {

    const { id } = useParams();
    const [imgUrl, setImgUrl] = useState("");
    const [imgName, setImgName] = useState("");
    const [imgType, setImgType] = useState("");
    const [modal, setModal] = useState(false);
    const [img, setImg] = useState();
    const [source, setSource] = useState();
    const [date, setDate] = useState();
    const [title, setTitle] = useState();
    const [link, setLink] = useState("");
    const [description, setDescription] = useState("");


    const [loading, setLoading] = useState(false);

    const history = useHistory();


    useEffect(() => {


        if (id) {

            try {
                (async () => {
                    const featureRef = doc(db, "feature", id)
                    const featureSnap = await getDoc(featureRef);

                    if (featureSnap.exists()) {
                        setSource(featureSnap.data().source);
                        setImgUrl(featureSnap.data().imgUrl)
                        setImgName(featureSnap.data().imgName)
                        setImgType(featureSnap.data().imgType)
                        setDate(featureSnap.data().date);
                        setTitle(featureSnap.data().title);
                        setLink(featureSnap.data().link);
                        setDescription(featureSnap.data().description)
                    } else {
                        // doc.data() will be undefined in this case
                    }

                    // setUsers(tUsers)

                })();
            } catch (error) {

            }

            // axios.get(`https://pastperfect-backend.herokuapp.com/v1/features/${id}`).then(function (response) {

            //     if (response.status === 200) {
            //         // setWork({
            //         //     img: response.data.img,
            //         //     img_url: response.data.img_url,
            //         //     title: response.data.title,
            //         //     description: response.data.description,
            //         //     client_name: response.data.client_name
            //         // }
            //         setSource(response.data.source);
            //         setImgUrl(response.data.img_url)
            //         setDate(response.data.date);
            //         setTitle(response.data.title);
            //         setLink(response.data.link);
            //         setDescription(response.data.description)

            //     } else {
            //         history.replace('/dashboard/features')
            //     }

            // }
            // )
        }
    }, [])


    const handleSubmit = async e => {
        e.preventDefault();


        try {

            setLoading(true);

            if (!img) {
                (async () => {
                    await addDoc(collection(db, "feature"), { source: source, date: date, title: title, link: link, description: description, imgName: imgName, imgUrl: imgUrl, imgType: imgType }).then(() => {
                        setLoading(false);
                        history.replace('/dashboard/features')
                    })
                })();
            } else {
                let img_type = img.name.split(".").pop()
                let img_name = generateCode()
                let new_img_name = img_name + "." + img_type;
                const storageRef = ref(storage, `images-v2/features/${new_img_name}`)
                uploadBytes(storageRef, img).then(() => {
                    getDownloadURL(storageRef).then(async (url) => {
                        await addDoc(collection(db, "feature"), { source: source, date: date, title: title, link: link, imgUrl: url, description: description, imgName: img_name, imgType: img_type }).then(() => {
                            setLoading(false);
                            history.replace('/dashboard/features')
                        })
                    })
                })
            }
        } catch (error) {
        }


        // setLoading(true);

        // const data = new FormData();
        // data.append("img", img);
        // data.set('source', source);
        // data.set('date', date);
        // data.set('title', title);
        // data.set('link', link);
        // data.set('description', description);

        // const response = await addFeature(data);

        // setLoading(false);
        // if (response.status == 200) {
        //     history.replace('/dashboard/features')
        // }
    }

    const handleEditText = async e => {
        e.preventDefault();
        try {
            setLoading(true);
            (async () => {
                await setDoc(doc(db, "feature", id), {
                    source: source,
                    date: date,
                    title: title,
                    link: link,
                    description: description,
                    imgName: imgName,
                    imgUrl: imgUrl,
                    imgType: imgType
                }).then(() => {
                    setLoading(false);
                    history.replace('/dashboard/features');
                })

            })();
        } catch (error) {

        }
        // setLoading(true);
        // const data = new FormData();
        // // data.append("img", img);
        // data.set('source', source);
        // data.set('date', date);
        // data.set('title', title);
        // data.set('link', link);
        // data.set('description', description)
        // // data.set('img_path', imgPath);

        // const response = await editFeatureText(data, id);
        // setLoading(false);
        // if (response.status === 200) {
        //     history.replace('/dashboard/features');

        // }
    }
    const handleEditImage = async e => {
        e.preventDefault();

        try {
            setLoading(true);
            if (imgUrl === "") {
                let img_type = img.name.split(".").pop()
                let img_name = generateCode()
                let new_img_name = img_name + "." + img_type;
                const storageRef = ref(storage, `images-v2/features/${new_img_name}`)
                uploadBytes(storageRef, img).then(() => {
                    getDownloadURL(storageRef).then(async (url) => {
                        await setDoc(doc(db, "feature", id), {
                            source: source,
                            date: date,
                            title: title,
                            link: link,
                            description: description,
                            imgName: img_name,
                            imgUrl: url,
                            imgType: img_type
                        }
                        ).then(() => {
                            setModal(false);
                            setImgName(img_name)
                            setImgType(img_type)
                            setImgUrl(url)
                            setLoading(false);
                        })
                    })
                })
            } else {
                let img_type = img.name.split(".").pop()
                let new_image_name = imgName + "." + img_type;
                const storageRef = ref(storage, `images-v2/features/${new_image_name}`)
                deleteObject(ref(storage, `images-v2/features/${imgName + "." + imgType}`)).then(() => {
                    uploadBytes(storageRef, img).then(() => {
                        getDownloadURL(storageRef).then(async (url) => {
                            await setDoc(doc(db, "feature", id), {
                                source: source,
                                date: date,
                                title: title,
                                link: link,
                                description: description,
                                imgName: imgName,
                                imgUrl: url,
                                imgType: img_type
                            }
                            ).then(() => {
                                setModal(false);
                                setImgUrl(url)
                                setLoading(false);
                            })
                        })
                    })
                })
            }

        } catch (error) {

        }
        // setLoading(true);
        // const data = new FormData();
        // data.append("img", img);
        // // data.set('title', title);
        // // data.set('description', description);
        // // data.set('client_name', clientName);

        // const response = await editFeatureImage(data, id);
        // if (response.status === 200) {
        //     setModal(false);
        //     setImgUrl(response.data.img_url)
        // }
        // setLoading(false);
    }





    return (
        <div>
            <div className="pro-row">
                <Link to='/dashboard/features'><i className="bi bi-chevron-left">back</i></Link>
                <div className="desktop-only"> <Profile /></div>
            </div>
            <Row className="margin-t">
                <Container>
                    <h1 className="dashboard-header">{id ? "Edit Feature" : "Add Feature"}</h1>
                    <p className="para mt-1">{id ? "Update your Feature" : "Show what you've worked upon to the entire world."}</p>

                    <Row className="margin-dash-4">
                        <Form onSubmit={id ? handleEditText : handleSubmit}>
                            <Row>
                                <Col xl={5} lg={12} cm={12} md={12} xs={12} sm={12}>
                                    <Form.Group>
                                        <Form.Label>Features Title</Form.Label>
                                        <Form.Control required type="text" value={title || ''} placeholder="Enter the title" onChange={e => setTitle(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mt-4 mb-4">
                                        <Form.Label>Source</Form.Label>
                                        <Form.Control required type="text" value={source || ''} placeholder="Name of the publisher" onChange={e => setSource(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mt-4 mb-4">
                                        <Form.Label>Link</Form.Label>
                                        <Form.Control type="url" value={link || ''} placeholder="Link to the article" onChange={e => setLink(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mt-4 mb-4">
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control required type="date" value={date || ''} placeholder="Publishing date" onChange={e => setDate(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mt-4 mb-4">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control as="textarea" name="description" value={description || ''} rows={5} type="text" placeholder="describe the feature here!" onChange={e => setDescription(e.target.value)} id="des" />
                                    </Form.Group>

                                </Col>
                                <Col xl={1} lg={12} cm={12} md={12} xs={12} sm={12}></Col>
                                <Col xl={5} lg={12} cm={12} md={12} xs={12} sm={12} className="img-col">
                                    {id ? <>{imgUrl ? <><p className="para">Existing Image:</p><img src={imgUrl} height="200" style={{ marginBottom: "20px" }} /><br /><Button className="submit-btn-small" onClick={() => setModal(true)}>Edit Image</Button></> : <> <p className="para">Insert a picture of the Feature</p><Button className="submit-btn" style={{ margin: "0px !important" }} onClick={() => setModal(true)}>Add an image</Button></>}</> : ""}
                                    {id ? "" : <> <p className="para">Insert a picture of the Feature</p><input type="file" className="img-fluid add-work-img" onChange={e => setImg(e.target.files[0])} name="img" /></>}
                                </Col>
                                <Button className="submit-btn" type="submit">
                                    {loading ? <LoadingSpinner /> : "Submit"}
                                </Button>
                            </Row>

                        </Form>
                    </Row>
                </Container>
            </Row>

            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modal}
                onHide={() => setModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditImage}>
                        <p className="para">insert a new image:</p>
                        <input type="file" className="img-fluid add-work-img" required onChange={e => setImg(e.target.files[0])} name="img" />
                        <Button className="submit-btn" type="submit">
                            {loading ? "Updating..." : "Submit"}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

