import React, { useState } from 'react'
import './Works.scss'
import { Row, Col } from "react-bootstrap";
import close from '../../../../Global/Images/close.svg'

const WorkOverlay = (props) => {
    const { client, title, des, img, toggle, addImgs, onHide } = props

    const [show, setShow] = useState(toggle)

    return <>

        {show ? <div className="work-overlay" >
            <a onClick={onHide}>
                {/* <img src={close} className="work-overlay-close" /> */}
                <i className="bi bi-x-lg work-overlay-close"></i>
            </a>

            <div className="work-overlay-content p-5">
                <p className="work-overlay-client">{client}</p>
                <h2 className="work-overlay-title">{title}</h2>
                <p className="work-overlay-description mb-5">{des}</p>
                <img src={img} alt="past perfect client work" className="work-overlay-img"></img>

                {addImgs.length > 0 ? <div className="additional-images-sec">
                    <Row className="add-img">
                        {addImgs.map((image, index) => (
                            <>
                                <Col lg={6} md={12} sm={12} xs={12} xl={6} className="gal-col mb-5">
                                    <div className="add-img-class">
                                        <img
                                            className="work-car-shd img-fluid"
                                            src={image.imgUrl}
                                            alt="First slide"
                                        />
                                    </div>

                                </Col>
                                {image.caption === null ? "" :
                                    <Col lg={5} md={12} sm={12} xs={12} xl={5} className="gal-col mb-5">
                                        <div className="about-overlay">
                                            <p className="desig-text"></p>
                                            <p className="name-text">About</p>
                                            <p className="bio-text mt-4">{image.caption}</p>
                                        </div>
                                    </Col>}
                            </>
                        ))}
                    </Row>
                </div> : ""}

            </div>
        </div > : ""}
    </>
}

export default WorkOverlay
