import React,{useState, useCallback} from 'react'
import {Button} from 'react-bootstrap'
import profileImage from '../images/profile.svg'
import LogoutUser from './logoutUser'
import './profile.scss'

const Profile = () => {
    const [show, setShow] = useState(false);

    const handleToggle = useCallback(() => setShow(prevShow => !prevShow),[])

    return (
        <div className="profile-area">
                <div className="profile-block only-desktop">
                    <img src={profileImage} alt="profile" className="profile-image img-fluid"/>
                    <div className="spacing-p"></div>
                    {/* <i className="bi bi-caret-down"></i> */}
                </div>

                <div className="profile-block only-mobile"  onClick={handleToggle}>
                    <img src={profileImage} alt="profile" className="profile-image img-fluid"/>
                    <i className="bi bi-caret-down"></i>
                    <div className="spacing-p"></div>
                </div>
                {show && (
                  <LogoutUser />  
                )}
        </div>
    )
}

export default Profile