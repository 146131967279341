import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
const LoadingSpinner = () => {
    return (
        <div>
            <FontAwesomeIcon icon={faSpinner} spin />
        </div>
    )
}

export default LoadingSpinner
