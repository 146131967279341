import React from 'react'
import './hamburger.scss'
import goBackIcon from '../Images/goBack.svg'
import { Row, Col, Container, Navbar, Button } from 'react-bootstrap'
import blackLogo from '../Images/blackLogo.svg'
import instagramLogo from '../Images/Instagram.svg'
import fbLogo from '../Images/Facebook.svg'
import twitterLogo from '../Images/Twitter.svg'


import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Link,
    useLocation,
    useHistory
} from "react-router-dom";

const Hamburger = ({ toggle }) => {
    const history = useHistory();
    const navName = [
        {
            'name': 'Home',
            'to': "/"
        },
        {
            'name': 'About Us',
            'to': "/about-us"
        },
        {
            'name': 'Our Verticals',
            'to': "/our-verticals"
        },
        {
            'name': 'Our Work',
            'to': "/our-works"
        },
        {
            'name': 'Features',
            'to': "/features"
        },
        {
            'name': 'Contact Us',
            'to': "/contact-us"
        },
    ]



    return (
        <div className="ham-area">
            <a className="goBack-link" onClick={toggle}><img src={goBackIcon} className="goBack-logo" /></a>
            <Container className="ham-container">
                {/* <Row>
                
                    <Col xl={1} lg={1} md={0} xs={12} sm={12}></Col>
                    <Col xl={5} lg={5} md={6} xs={12} sm={12}  className="ham-links">

                        {navName.map((nav, index) => (
                            <NavLink activeClassName="active" to={nav.to} className="text-d">
                                <div className="ham-menu-link">
                                    <h2>{nav.name}</h2>
                                </div>
                            </NavLink>
                        ))}
                    </Col>
                    <Col xl={1} lg={1} md={1} xs={12} sm={12}>
                    </Col>
                    <Col xl={5} lg={5} md={5} xs={12} sm={12} className="ham-info">
                        <img src={blackLogo} className="ham-logo"/>
                        <p className="para mt-5">Lorem Ipsum is simply<br></br> dummy text of the printing<br></br> and typesetting industry. </p>
                        <div className="ham-social mt-5">
                            <img src={instagramLogo} className="social-icon"/>
                            <img src={fbLogo} className="social-icon"/>
                            <img src={twitterLogo} className="social-icon"/>
                        </div>
                    </Col>
                </Row> */}
                {navName.map((nav, index) => (
                    <NavLink exact key={index} activeClassName="active-nav" to={nav.to} className="text-d">
                        <div className="ham-menu-link">
                            <h2>{nav.name}</h2>
                        </div>
                    </NavLink>
                ))}
                        <div className="ham-menu-link">
                            <a href="http://blog.pastperfect.co.in/" style={{textDecoration:"none"}} target="_blank"><h2>Blog</h2></a>
                        </div>
            </Container>
        </div>
    )
}



export default Hamburger

