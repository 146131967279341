import React, { useEffect } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'


import vertical_1 from '../../../../Global/Images/vertical_1.png'
import vertical_2 from '../../../../Global/Images/vertical_2.jpg'
import vertical_3 from '../../../../Global/Images/vertical_3.jpg'
import './Verticals.scss'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Link,
    useLocation
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function Verticals() {
    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);
    return (
        <div>
            <Container>
                <Row className="vertical-row">
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={vertical_1} className="img-fluid vertical-page-img" />
                    </Col>

                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="detail-col-vertical-page">
                        {/* <div className="number-vertical">01</div> */}
                        <h2 className="vertical-page-detail-header">
                            <i className="bi bi-server"></i>  Archiving & Documentation</h2>
                        <p className="vertical-page-para m-b">
                            From building your collection to deep diving into your history, we help you identify and highlight your unique story. <br></br> <br></br>   Through scientific archival principles and thorough documentation, we ensure that your collection serves as a reflection of the many facets of your organization.<br></br><br></br>     Your archive is the memory keeper of the organization and is a valuable intelligence centre of your past that can inform your decision making in the present.
                        </p>
                        <Link to="/contact-us"><button className="box-btn">Learn More</button></Link>
                    </Col>
                </Row>

                <Row className="vertical-row  row-margin m-v">
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="detail-col-vertical-page  second">
                        {/* <div className="number-vertical">01</div> */}
                        <h2 className="vertical-page-detail-header"><i className="bi bi-search"></i> Research Support</h2>
                        <p className="vertical-page-para m-b">No event has, and can ever be,  studied in isolation. Over the  years, Past Perfect has had the opportunity to conduct  dedicated research projects for academicians, publishers as well as film producers.<br></br><br></br>     Our inquisitive and curious nature pushes us to dig into multiple histories, further connecting them to a larger context. Using our research expertise, we help you unravel authentic stories hidden in unexplored primary material and oral history interviews, thus facilitating a reinterpretation of existing narratives.</p>
                        <Link to="/contact-us"><button className="box-btn">Learn More</button></Link>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="first">
                        <img src={vertical_2} className="img-fluid vertical-page-img" />
                    </Col>

                </Row>

                <Row className="vertical-row mb-5">
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={vertical_3} className="img-fluid vertical-page-img" />
                    </Col>

                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="detail-col-vertical-page">
                        {/* <div className="number-vertical">01</div> */}
                        <h2 className="vertical-page-detail-header"><i className="bi bi-journal-bookmark"></i> Curatorial Services</h2>
                        <p className="vertical-page-para m-b">There are varied ways to reimagine history and communicate legacies. For an institution, your archive can be repurposed for brand building, establishing a credibility or engaging your stakeholders.<br></br><br></br>     At Past Perfect, we ensure that your history is retold in a manner that is engaging for a wider audience.<br></br><br></br>     On carefully selecting apt material from your collection, we partner with creative agencies to craft stories that resonate.</p>
                        <Link to="/contact-us"><button className="box-btn">Learn More</button></Link>
                    </Col>
                </Row>
            </Container>
            {/* <Container>
                

                <Row className="vertical-page-row" data-aos="fade-up">
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={vertical_1} className="img-fluid vertical-page-img" />
                    </Col>
                    
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="detail-col-vertical-page">
                <div className="number-vertical">01</div>
                        <h2 className="vertical-page-detail-header">Archiving & Documentation</h2>
                        <p className="vertical-page-para m-b">
                        From building your collection to deep diving into your history, we help you identify and highlight your unique story.

 

Through scientific archival principles and thorough documentation, we ensure that your collection serves as a reflection of the many facets of your organization.

 

Your archive is the memory keeper of the organization and is a valuable intelligence centre of your past that can inform your decision making in the present.
                        </p>
                        <Link to="/contact-us"><button className="box-btn">Learn More</button></Link>
                    </Col>
                </Row>
                <Row className="vertical-page-row" data-aos="fade-up">
                <Col xl={6} lg={6} md={12} sm={12} xs={12} className="detail-col-vertical-page">
                        <div className="number-vertical">02</div>
                        <h2 className="vertical-page-detail-header">Vertical Name</h2>
                        <p className="para m-b">
                            Collection Management involves the development, storage, preservation and documentation of your heritage. We assess your most valuable archival assets, identify your immediate needs and challenges, and package the type of archive that will work best for you. We identify the best way to intellectually and physically arrange your available assets, catalogue and develop finding aids to ensure quick retrieval.
                        </p>
                        <Link to="/contact-us"><button className="box-btn">Learn More</button></Link>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={vertical_1} className="img-fluid vertical-page-img" />
                    </Col>
                    
                    
                </Row>
                <Row className="vertical-page-row" data-aos="fade-up">
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={vertical_1} className="img-fluid vertical-page-img" />
                    </Col>
                    
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="detail-col-vertical-page">
                        <div className="number-vertical">03</div>
                        <h2 className="vertical-page-detail-header">Vertical Name</h2>
                        <p className="para m-b">
                            Collection Management involves the development, storage, preservation and documentation of your heritage. We assess your most valuable archival assets, identify your immediate needs and challenges, and package the type of archive that will work best for you. We identify the best way to intellectually and physically arrange your available assets, catalogue and develop finding aids to ensure quick retrieval.
                        </p>
                        <Link to="/contact-us"><button className="box-btn">Learn More</button></Link>
                    </Col>
                </Row>
            </Container> */}
        </div>
    )
}

export default Verticals
