import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";
import './menuItem.scss'


const MobileMenuItem = (props) => {

  const { name, icon, to } = props

  return (
    <div>
      <NavLink to={to} activeClassName="active" className="text-d menuItem">
        <div className="menu-d">
            <div className="mobile-nav-link">
                <i className={icon}></i>
                <div className="mobile-menu-link">{name}</div>
            </div>
        </div>
      </NavLink>
    </div>
  )
}

export default MobileMenuItem