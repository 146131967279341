import React from 'react'
import {Row,Container, Col} from 'react-bootstrap'

function Hero() {
    return (
        <div className="hero-area">
            <Container>
            <Row>
                    <Col lg={1} md={2} sm={12} xs={12} xl={1}>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} xl={6}>
                    {/* <p className="para">Say hi to the team</p> */}
                    <h1 className="big-header">Contact Us.</h1>
                    <p className="para mt-3">Confused about where and how to begin?
<br></br>
We'd recommend starting with an exploratory<br></br> conversation to assess the possibilities.</p>

                    
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Hero
