import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Card, Button } from 'react-bootstrap'
import Profile from '../../Global/Profile/profile.js'
import './features.scss'
import AddWork from './AddFeature/addFeature.js'
import getCookie from '../../functions.js';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch
} from "react-router-dom";

import axios from 'axios';
import { set } from 'lodash'
import { collection, deleteDoc, doc, getDocs, query } from 'firebase/firestore'
import { db, storage } from '../../../../firebase.js'
import { deleteObject, ref as storageRef } from 'firebase/storage'

// async function deleteFeatures(id) {
//     const url = `https://pastperfect-backend.herokuapp.com/v1/features/${id}`;
//     const headers = {
//         "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
//     };
//     return axios.delete(url, { headers }).then(function (response) {
//         window.location.reload(false);
//         return response;
//     }).catch(function (error) {
//         return error;
//     })
// }


export default function Features() {

    const [features, setFeatures] = useState([]);

    const handleDelete = async (e, id, imgName, imgType) => {
        e.preventDefault();

        try {

            if (imgName.trim().length > 10) {
                let path = `images-v2/features/${imgName + "." + imgType}`
                const imageRef = storageRef(storage, path);

                deleteObject(imageRef)
            }
            await deleteDoc(doc(db, "feature", id))
            window.location.reload(false);
        } catch (error) {
        }
    }


    useEffect(() => {
        // axios.get('https://pastperfect-backend.herokuapp.com/v1/features').then(function (response) {
        //     setFeatures(response.data)
        // })

        let tFeatures = []
        try {
            (async () => {
                const featureRef = query(collection(db, "feature"));
                const featureSnap = await getDocs(featureRef);

                featureSnap.forEach((doc) => {
                    tFeatures.push({
                        doc_id: doc.id,
                        data: doc.data()
                    })
                })

                setFeatures(tFeatures)

            })();
        } catch (error) {
        }

    }, []);



    // const allFeatures = [
    //     {
    //         "source":"The Better India",
    //         "date":"June 13, 2020​",
    //         "title":"Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link":"https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img":"https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     },
    //     {
    //         "source":"The Better India",
    //         "date":"June 13, 2020​",
    //         "title":"Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link":"https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img":"https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     },
    //     {
    //         "source":"The Better India",
    //         "date":"June 13, 2020​",
    //         "title":"Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link":"https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img":"https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     },
    //     {
    //         "source":"The Better India",
    //         "date":"June 13, 2020​",
    //         "title":"Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link":"https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img":"https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     }
    // ]

    return (
        <div>
            {/* <WorkModal
                show={modal}
                onHide={() => setModal(false)}
            /> */}
            <Row>
                <div className="desktop-only"> <Profile /></div>
            </Row>
            <Container>
                <Row className="margin-dash row-margin">

                    <h1 className="dashboard-header">Features</h1>
                    <Row>
                        <Col lg={6} xl={8} md={12}>
                            <p className="para mt-1">Create, View, Update, Delete your Features. Over here</p>
                        </Col>
                        <Col lg={2} xl={2} md={12}></Col>
                        <Col lg={5} xl={2} md={12} className="export">
                            <Link to='/dashboard/addFeature' className="text-d">
                                <Button className="add-work-btn">Add Features</Button>
                            </Link>
                        </Col>
                    </Row>

                    <Row className="work-card-deck">

                        {features.map((feature, index) => (
                            <Col key={index} lg={6} xl={4} md={12} sm={6} xs={12}>
                                <Card className="feature-card mb-4">
                                    <Card.Body>
                                        <div className="mt-3 mb-2"><span className="source">{feature.data.source}</span> | <span className="date">{feature.data.date}</span></div>
                                        <Card.Title className="card-title-dash">{feature.data.title}</Card.Title>
                                        <div className="btn-grp-2">
                                            <Link to={`/dashboard/editfeature/${feature.doc_id}`} className="edit-btn-1">Edit</Link>
                                            <Button style={{ backgroundColor: 'unset', border: 'unset' }} onClick={e => handleDelete(e, feature.doc_id, feature.data.imgName, feature.data.imgType)}><i className="bi bi-trash"></i></Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Row>
            </Container>
        </div>
    )
}
