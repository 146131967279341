import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Card, Button } from 'react-bootstrap'
import Profile from '../../Global/Profile/profile.js'
import './Team.scss'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch
} from "react-router-dom";

import axios from 'axios';


async function deleteTeamMember(id) {
    const url = `https://pastperfect-backend.herokuapp.com/v1/team/${id}`;
    const headers = {
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };
    return axios.delete(url, { headers }).then(function (response) {
        window.location.reload(false);
        return response;
    }).catch(function (error) {
        return error;
    })
}


export default function Team() {

    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        axios.get('https://pastperfect-backend.herokuapp.com/v1/team').then(function (response) {
            setTeamMembers(response.data)
        })
    }, []);


    // const allFeatures = [
    //     {
    //         "source":"The Better India",
    //         "date":"June 13, 2020​",
    //         "title":"Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link":"https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img":"https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     },
    //     {
    //         "source":"The Better India",
    //         "date":"June 13, 2020​",
    //         "title":"Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link":"https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img":"https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     },
    //     {
    //         "source":"The Better India",
    //         "date":"June 13, 2020​",
    //         "title":"Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link":"https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img":"https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     },
    //     {
    //         "source":"The Better India",
    //         "date":"June 13, 2020​",
    //         "title":"Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link":"https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img":"https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     }
    // ]

    return (
        <div>
            {/* <WorkModal
                show={modal}
                onHide={() => setModal(false)}
            /> */}
            <Row>
                <div className="desktop-only"> <Profile /></div>
            </Row>
            <Container>
                <Row className="margin-dash row-margin">

                    <h1 className="dashboard-header">Our Team</h1>
                    <Row>
                        <Col lg={6} xl={8} md={12}>
                            <p className="para mt-1">Create, View, Update, Delete Team Members. </p>
                        </Col>
                        <Col lg={2} xl={2} md={12}></Col>
                        <Col lg={5} xl={2} md={12} className="export">
                            <Link to='/dashboard/addTeam' className="text-d">
                                <Button className="add-work-btn">Add Team Member</Button>
                            </Link>
                        </Col>
                    </Row>

                    <Row className="work-card-deck">
                        {teamMembers.map((teamMember, index) => (
                            <Col lg={6} xl={4} md={12} sm={6} xs={12} key={index}>
                                <Card className="work-card">
                                    <Card.Img variant="top" src={teamMember.img_url} className="work-img" />
                                    <Card.Body>
                                        <Card.Title className="card-title-dash">{teamMember.name}</Card.Title>
                                        <div className="btn-grp-2">
                                            <Link to={`/dashboard/editTeam/${teamMember.id}`} className="edit-btn-1">Edit</Link>
                                            <Button style={{ backgroundColor: 'unset', border: 'unset' }} onClick={function () {
                                                deleteTeamMember(teamMember.id);

                                            }}><i className="bi bi-trash"></i></Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Row>
            </Container>
        </div>
    )
}
