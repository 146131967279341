import React from 'react'
import { Row, Col, Container, Navbar, Button } from 'react-bootstrap'

function Hero() {
    return (
        <div className="hero-area">
            <Container>
                <Row>
                    <Col lg={1} md={2} sm={12} xs={12} xl={1}>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} xl={6}>
                        <h1 className="big-header">Our Verticals</h1>
                        <p className="para mt-3 w-75">From managing historical collections to identifying newer threads in your story, we help you in varied ways to capture your undying zeitgeist.</p>
                        {/* <a href="#" className="edit-btn-2">Learn More</a> */}
                    </Col>
                    <Col lg={5} md={6} sm={12} xs={12} xl={5} className="side-col"></Col>

                </Row>
            </Container>
        </div>
    )
}

export default Hero
