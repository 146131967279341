import React from 'react'
import Navbar from '../../Global/navbar/navbar'
import './Homepage.scss'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import AdminHome from '../../../AdminSide/Screens/Home.js'
import Hero from './Components/Hero/Hero.js'
import OurVerticals from './Components/ourVerticals/ourVerticals.js'
import OurWorks from './Components/ourWorks/ourWorks.js'
import Featured from './Components/featured/featured.js'
import ContactCTA from '../../Global/ContactCTA/contactCTA.js'
import Footer from '../../Global/footer/footer.js'

export default function Homepage() {
    return (
        <div className="home-hero-area">
            <Navbar />
            <Hero />
            <OurVerticals />
            <OurWorks />
            <Featured />
            <ContactCTA />
            <Footer />
        </div>
    )
}
