import React, { Fragment, useState, useEffect } from 'react'
import './ourWorks.scss'
import { Container, Col, Row, Card, Button } from 'react-bootstrap'
import Dots from '../../../../Global/Images/back_search.svg'

import { Timeline, Event } from "react-timeline-scribble";

import axios from "axios"
import AOS from "aos";
import "aos/dist/aos.css";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Link,
    useLocation
} from "react-router-dom";
import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { db } from '../../../../../../firebase';
import { orderBy } from 'lodash';

function OurWorks() {
    let [works, setWorks] = useState([]);
    useEffect(() => {
        try {
            (async () => {
                const priorityRef = query(collection(db, "priority"), orderBy("id"));
                const prioritySnap = await getDocs(priorityRef);

                let tWorks = [];

                await Promise.all(
                    prioritySnap.docs.map(async (document) => {
                        const workRef = doc(db, "work", document.data().workId);
                        const workSnap = await getDoc(workRef);
                        if (workSnap.exists()) {
                            let tWork = {
                                priority: document.id,
                                id: workSnap.id,
                                data: workSnap.data(),
                            };
                            tWorks.push(tWork);
                        }
                    })
                );

                setWorks(tWorks);
            })();
        } catch (error) {
        }
    }, []);

    // works = works.slice(0, 3);

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);
    return (
        <div className="works-area">
            {/* <img src={SemiCircle} className="semi-circle img-fluid"></img> */}

            <Container>
                <Row className="row-margin">
                    <Col xl={1} lg={1} md={1} sm={12} xs={12}></Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <h2 className="big-header">Our Work.</h2>
                    </Col>

                    <Col lg={1} md={1} sm={12} xs={12} xl={1}></Col>
                    <Col lg={3} md={5} sm={12} xs={12} xl={3}>
                        {/* <p className="para mt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing.</p> */}
                        {/* <a href="#" className="edit-btn-2">Learn More</a> */}
                    </Col>
                </Row>
                <Row className="history-row">
                    <Col xl={1} lg={1} md={1} sm={12} xs={12}></Col>
                    <Col xl={11} lg={11} md={11} sm={12} xs={12} className="timeline-row">
                        <Fragment>
                            <Timeline>
                                {/* <img src={Dots} className="dots-works img-fluid"></img> */}
                                {works.map((work, index) => (
                                    <Event key={index} className="event-works">
                                        <Row data-aos="fade-up" className="margin-timeline event-row" key={index}>
                                            <Col xl={5} lg={12} md={12} sm={12} xs={12}>
                                                <img src={work.data.imgUrl} className="img-fluid our-work-img" alt="" />
                                            </Col>
                                            <Col xl={1} lg={12} md={12} sm={12} xs={12}></Col>
                                            <Col xl={5} lg={12} md={12} sm={12} xs={12} className="work-info-col m-t">
                                                <h3 className="work-header">{work.data.clientName}</h3>
                                                <p className="category-work">{work.data.title}</p>
                                                <p className="para mt-4 mb-4 hero-works-para">
                                                    {work.data.description}
                                                </p>

                                                {/* <a href="#" className="edit-btn-2">Learn More</a> */}
                                            </Col>
                                            <Col xl={1} lg={12} md={12} sm={12} xs={12}></Col>
                                        </Row>
                                    </Event>
                                ))}
                                <Event>
                                    <Link to="/our-works"><button className="box-btn">Explore More <i className="bi bi-search ml-3"></i></button></Link>
                                </Event>
                            </Timeline>
                        </Fragment>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default OurWorks
