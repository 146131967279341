import React from 'react'
import Navbar from '../../Global/navbar/navbar'
import Footer from '../../Global/footer/footer.js'
import Hero from './Components/Hero/Hero.js'
import ContactCTA from '../../Global/ContactCTA/contactCTA.js'

export default function Aboutus() {
    return (
        <div>
            <Navbar />
            <Hero />
            <ContactCTA />
            <Footer />
        </div>
    )
}
