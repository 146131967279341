import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Row, Col, Container, Card } from 'react-bootstrap'
import './addWork.scss'
import Profile from '../../../Global/Profile/profile.js'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory,
    Link
} from "react-router-dom";
import axios from 'axios';
import LoadingSpinner from '../../../Global/LoadingSpinner';
import { db, storage } from '../../../../../firebase';
import { addDoc, arrayRemove, arrayUnion, collection, doc, getDoc, setDoc, updateDoc, getDocs } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';

function generateCode() {
    const array = new Uint8Array(8);
    window.crypto.getRandomValues(array);
    return Array.from(array, (byte) => ('0' + byte.toString(16)).slice(-2)).join('');
}

async function addWork(data) {
    const url = 'https://pastperfect-backend.herokuapp.com/v1/portfolio';
    const headers = {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };
    return axios.post(url, data, { headers }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error;
    });
}

async function deleteAdditionalImage(id) {
    const url = `https://pastperfect-backend.herokuapp.com/v1/portfolio/image/additional/${id}`;
    const headers = {
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };
    return axios.delete(url, { headers }).then(function (response) {
        window.location.reload(false);
        return response;
    }).catch(function (error) {
        return error;
    })
}

async function addAdditionalImage(data, work_id) {
    const url = `https://pastperfect-backend.herokuapp.com/v1/portfolio/image/additional/${work_id}`

    const headers = {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };

    return axios.post(url, data, { headers }).then(function (response) {

        if (response.status === 200) {
            window.location.reload(false);
        }

        return response;



    }).catch(function (error) {
        return error;
    });

}

async function editWorkText(data, id) {
    const url = `https://pastperfect-backend.herokuapp.com/v1/portfolio/${id}`;
    const headers = {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };

    return axios.put(url, data, { headers }).then(function (response) {
        return response;
    }).catch(function (error) {
        return error;
    });

}
async function editWorkImage(data, id) {
    const url = `https://pastperfect-backend.herokuapp.com/v1/portfolio/image/${id}`;
    const headers = {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };

    return axios.put(url, data, { headers }).then(function (response) {

        if (response.status === 200) {
            window.location.reload(false);
        }

        return response;
    }).catch(function (error) {
        return error;
    });

}
async function editWorkAdditionalImage(data, id) {
    const url = `https://pastperfect-backend.herokuapp.com/v1/portfolio/image/additional/${id}`;
    const headers = {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
    };

    return axios.put(url, data, { headers }).then(function (response) {

        if (response.status === 200) {
            window.location.reload(false);
        }

        return response;
    }).catch(function (error) {
        return error;
    });

}

// async function uploadImage(postData, s3Data) {
//     return axios.post(s3Data.url, postData).then(function (response) {
//         return response;
//     }).catch(function (error) {
//         return error;
//     });
// }





export default function AddWork() {


    const { id } = useParams();

    const [coverImg, setCoverImg] = useState();
    const [caption, setCaption] = useState("");


    const [additionalImages, setAdditionalImages] = useState([]);
    const [selectedAdditionalImage, setSelectedAdditionalImage] = useState({});

    const [imgUrl, setImgUrl] = useState();
    const [imgName, setImgName] = useState("");
    const [imgType, setImgType] = useState("");

    const [modal, setModal] = useState(false);
    const [addImageModal, setAddImageModal] = useState(false);
    const [newAddImgModal, setNewAddImageModal] = useState(false);

    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [clientName, setClientName] = useState();

    const [loading, setLoading] = useState(false);

    const history = useHistory();



    // useEffect(() => {
    //     if (id) {
    //         axios.get(`https://pastperfect-backend.herokuapp.com/v1/portfolio/${id}`).then(response => setWork({
    //             img: response.data.img,
    //             img_url: response.data.img_url,
    //             title: response.data.title,
    //             description: response.data.description,
    //             client_name: response.data.client_name
    //         }))
    //     }
    // }, [])


    useEffect(() => {
        if (id) {

            try {
                (async () => {
                    const workRef = doc(db, "work", id)
                    const workSnap = await getDoc(workRef);

                    if (workSnap.exists()) {
                        setTitle(workSnap.data().title);
                        setImgUrl(workSnap.data().imgUrl);
                        setImgName(workSnap.data().imgName);
                        setImgType(workSnap.data().imgType);
                        setAdditionalImages(workSnap.data().addImages)
                        setClientName(workSnap.data().clientName);
                        setDescription(workSnap.data().description);
                    } else {
                        // doc.data() will be undefined in this case
                        history.replace('/dashboard/works')
                    }

                    // setUsers(tUsers)

                })();
            } catch (error) {

            }

            // axios.get(`https://pastperfect-backend.herokuapp.com/v1/portfolio/${id}`).then(function (response) {

            //     if (response.status === 200) {
            //         // setWork({
            //         //     img: response.data.img,
            //         //     img_url: response.data.img_url,
            //         //     title: response.data.title,
            //         //     description: response.data.description,
            //         //     client_name: response.data.client_name
            //         // }
            //         setTitle(response.data.title);
            //         setImgPath(response.data.img);
            //         setAdditionalImages(response.data.images)
            //         setImgUrl(response.data.img_url)
            //         setClientName(response.data.client_name);
            //         setDescription(response.data.description);
            //     } else {
            //         history.replace('/dashboard/works')
            //     }

            // }
            // )
        }
    }, [])




    // if (work) {
    //     setImg(work.img);
    //     setTitle(work.title);
    //     setDescription(work.description);
    //     setClientName(work.client_name);
    // }

    async function getHighestIndex() {
        try {
            const workSnap = await getDocs(collection(db, 'work'));

            let highestIndex = 0;
            workSnap.forEach((doc) => {
                const work = doc.data();
                if (work.index > highestIndex) {
                    highestIndex = work.index;
                }
            });

            return highestIndex;
        } catch (error) {
            console.error("Error getting highest index:", error);
        }
    }



    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setLoading(true);

            // Find the highest index for existing works
            const highestIndex = await getHighestIndex();

            let img_type = coverImg.name.split(".").pop();
            let img_name = generateCode();
            let new_img_name = img_name + "." + img_type;
            const storageRef = ref(storage, `images-v2/works/${new_img_name}`);
            uploadBytes(storageRef, coverImg).then(() => {
                getDownloadURL(storageRef).then(async (url) => {
                    await addDoc(collection(db, "work"), {
                        title: title,
                        clientName: clientName,
                        imgUrl: url,
                        description: description,
                        imgName: img_name,
                        imgType: img_type,
                        addImages: additionalImages,
                        index: highestIndex + 1 // Add index for new work
                    }).then(() => {
                        setLoading(false);
                        history.replace('/dashboard/works');
                    });
                });
            });
        } catch (error) {
            console.error("Error adding new work:", error);
        }
    }

    const handleEditText = async e => {
        e.preventDefault();
        try {
            setLoading(true);
            (async () => {
                await setDoc(doc(db, "work", id), {
                    title: title,
                    description: description,
                    clientName: clientName,
                    imgUrl: imgUrl,
                    imgName: imgName,
                    imgType: imgType,
                    addImages: additionalImages,
                }).then(() => {
                    setLoading(false);

                    history.replace('/dashboard/works');
                })

            })();
        } catch (error) {

        }
        // setLoading(true);
        // const data = new FormData();
        // // data.append("img", img);
        // data.set('title', title);
        // data.set('description', description);
        // data.set('client_name', clientName);
        // // data.set('img_path', imgPath);

        // const response = await editWorkText(data, id);
        // setLoading(false);
        // if (response.status === 200) {
        //     history.replace('/dashboard/works');

        // }
    }

    const handleSubmitAddImage = async e => {
        e.preventDefault();

        try {
            setLoading(true);
            let img_type = coverImg.name.split(".").pop()
            let img_name = generateCode()
            let new_img_name = img_name + "." + img_type;
            const storageRef = ref(storage, `images-v2/works/${new_img_name}`)
            uploadBytes(storageRef, coverImg).then(() => {
                getDownloadURL(storageRef).then(async (url) => {
                    await setDoc(doc(db, "work", id), {
                        title: title,
                        description: description,
                        clientName: clientName,
                        imgUrl: imgUrl,
                        imgName: imgName,
                        imgType: imgType,
                        addImages: [...additionalImages, {
                            caption: caption,
                            imgName: img_name,
                            imgUrl: url,
                            imgType: img_type
                        }],
                    }).then(() => {
                        setNewAddImageModal(false);
                        setLoading(false);
                        window.location.reload(false);
                    })
                })
            })

        } catch (error) {
        }

        // setLoading(true);
        // const data = new FormData();
        // data.append("img", coverImg);
        // if (caption) {
        //     data.set('caption', caption)
        // }
        // const response = await addAdditionalImage(data, id);

        // if (response.status === 200) {
        //     setNewAddImageModal(false);
        // }

        // setLoading(false);
    }

    const handleEditImage = async e => {
        e.preventDefault();
        try {
            console.log(`Uploaded Image: ${coverImg.name} \n Existing Image: ${imgName}`)
            let img_type = coverImg.name.split(".").pop()
            let new_image_name = imgName + "." + img_type;
            const storageRef = ref(storage, `images-v2/works/${new_image_name}`)
            deleteObject(ref(storage, `images-v2/works/${imgName + "." + imgType}`)).then(() => {
                uploadBytes(storageRef, coverImg).then(() => {
                    getDownloadURL(storageRef).then(async (url) => {
                        await setDoc(doc(db, "work", id), {
                            title: title,
                            description: description,
                            clientName: clientName,
                            imgUrl: imgUrl,
                            imgName: imgName,
                            imgType: img_type,
                            addImages: additionalImages,
                        }
                        ).then(() => {
                            setModal(false);
                            setImgUrl(url)
                            setLoading(false);
                        })
                    })
                })
            })
        } catch (error) {

        }

        // e.preventDefault();
        // setLoading(true);
        // const data = new FormData();
        // data.append("img", coverImg);
        // // data.set('title', title);
        // // data.set('description', description);
        // // data.set('client_name', clientName);

        // const response = await editWorkImage(data, id);
        // if (response.status === 200) {
        //     setModal(false);
        //     setImgUrl(response.data.img_url)
        // }
        // setLoading(false);
    }
    const handleEditAddImage = async e => {
        e.preventDefault();
        try {

            if (!coverImg) {
                if (caption) {
                    await setDoc(doc(db, "work", id), {
                        title: title,
                        description: description,
                        clientName: clientName,
                        imgUrl: imgUrl,
                        imgName: imgName,
                        imgType: imgType,
                        addImages: arrayUnion({
                            caption: caption,
                            imgName: selectedAdditionalImage.imgName,
                            imgUrl: selectedAdditionalImage.imgUrl,
                            imgType: selectedAdditionalImage.imgType
                        }
                        )
                    }
                    ).then(() => {
                        setAddImageModal(false);
                        setLoading(false);
                        window.location.reload(false);
                    })
                }
            } else {
                let img_type = coverImg.name.split(".").pop()

                let new_image_name = selectedAdditionalImage.imgName + "." + img_type;
                const storageRef = ref(storage, `images-v2/works/${new_image_name}`)
                deleteObject(ref(storage, `images-v2/works/${selectedAdditionalImage.imgName + "." + selectedAdditionalImage.imgType}`)).then(() => {
                    uploadBytes(storageRef, coverImg).then(() => {
                        getDownloadURL(storageRef).then(async (url) => {
                            await setDoc(doc(db, "work", id), {
                                title: title,
                                description: description,
                                clientName: clientName,
                                imgUrl: imgUrl,
                                imgName: imgName,
                                imgType: imgType,
                                addImages: arrayUnion({
                                    caption: caption,
                                    imgName: selectedAdditionalImage.imgName,
                                    imgUrl: url,
                                    imgType: img_type
                                }
                                )
                            }
                            ).then(() => {
                                setAddImageModal(false);
                                setLoading(false);
                                window.location.reload(false);
                            })
                        })
                    })
                })
            }


        } catch (error) {
        }

        // setLoading(true);
        // const data = new FormData();
        // data.append("img", coverImg);
        // if (caption) {
        //     data.set('caption', caption)
        // }
        // // data.set('title', title);
        // // data.set('description', description);
        // // data.set('client_name', clientName);

        // const response = await editWorkAdditionalImage(data, selectedAdditionalImage);
        // if (response.status === 200) {
        //     setAddImageModal(false);
        // }
        // setLoading(false);
    }

    const handleDeleteAddImage = async (e, imgUrl, imgName, imgType, caption) => {
        e.preventDefault();

        try {

            if (imgName.trim().length > 10) {
                let path = `images-v2/works/${imgName + "." + imgType}`
                const imageRef = ref(storage, path);
                deleteObject(imageRef).then(async () => {
                    await updateDoc(doc(db, "work", id), {
                        addImages: arrayRemove({
                            caption: caption,
                            imgUrl: imgUrl,
                            imgName: imgName,
                            imgType: imgType
                        })
                    }).then(() => {
                        window.location.reload(false);
                    })

                })
            }
        } catch (error) {
        }
    }

    return (
        <div className="add-work-area">
            <div className="pro-row">
                <Link to='/dashboard/works'><i className="bi bi-chevron-left">back</i></Link>
                <div className="desktop-only"> <Profile /></div>
            </div>
            <Row className="margin-t">



                <Container>
                    <h1 className="dashboard-header">{id ? "Edit Work" : "Add Work"}</h1>
                    <p className="para mt-1">{id ? "Update your work" : "Show what you've worked upon to the entire world."}</p>

                    <Row className="margin-dash-4">
                        <Form onSubmit={id ? handleEditText : handleSubmit}>

                            <Row>
                                <Col xl={6} lg={12} cm={12} md={12} xs={12} sm={12}>
                                    <Form.Group>
                                        <Form.Label>Work Title</Form.Label>
                                        <Form.Control required type="text" name="title" value={title || ''} placeholder="Enter the title" onChange={e => setTitle(e.target.value)} id="title" />
                                    </Form.Group>
                                    <Form.Group className="mt-4 mb-4">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control required as="textarea" name="description" value={description || ''} rows={5} type="text" placeholder="Write down the description of the work" onChange={e => setDescription(e.target.value)} id="des" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Client Name</Form.Label>
                                        <Form.Control type="text" name="client_name" placeholder="Client Name" value={clientName || ''} required onChange={e => setClientName(e.target.value)} id="clientName" />
                                    </Form.Group>

                                    <br />
                                    <br />



                                    {id ? <Row className="additional-img-row">
                                        <p className="normal mb-4">Additional images: </p>
                                        {additionalImages.map((image, index) => (
                                            <Col xl={4} lg={4} cm={12} md={12} xs={12} sm={12} style={{ textAlign: "center" }} key={index}>
                                                {/* <img key={index} src={image.img_url} height="150" style={{ border: "2px solid #FBAF42" }} />
                                                <br />
                                                <br />
                                                <Row style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
                                                    <Button className="add-img-btn edit" onClick={function () {
                                                        setSelectedAdditionalImage(image.id);
                                                        setAddImageModal(true);
                                                    }}><i class="bi bi-pencil-square"></i></Button>
                                                    <Button className="add-img-btn del" onClick={() => deleteAdditionalImage(image.id)}><i class="bi bi-trash"></i></Button>
                                                </Row>
                                                <br />
                                                <br /> */}
                                                <Card className="work-card">
                                                    <Card.Img variant="top" src={image.imgUrl} className="work-img" />
                                                    <Card.Body>
                                                        <div className="btn-grp-2">

                                                            <a onClick={function () {
                                                                setSelectedAdditionalImage({ imgName: image.imgName, imgType: image.imgType, imgUrl: image.imgUrl });
                                                                if (image.caption) {
                                                                    setCaption(image.caption)
                                                                }

                                                                setAddImageModal(true);
                                                            }} className="edit-btn-1">Edit</a>
                                                            <Button style={{ backgroundColor: 'unset', border: 'unset' }} onClick={(e) => handleDeleteAddImage(e, image.imgUrl, image.imgName, image.imgType, image.caption)}><i className="bi bi-trash"></i></Button>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                        {additionalImages.length < 3 ? <>
                                            <Col xl={4} lg={4} cm={12} md={12} xs={12} sm={12} style={{ textAlign: "center" }}>

                                                <Card className="work-card" onClick={function () {
                                                    setNewAddImageModal(true)
                                                }}>
                                                    <i className="bi bi-plus"></i>
                                                </Card>
                                            </Col>
                                        </> : ""}
                                    </Row> : ""}


                                </Col>
                                <Col xl={1} lg={12} cm={12} md={12} xs={12} sm={12} />
                                <Col xl={5} lg={12} cm={12} md={12} xs={12} sm={12} className="img-col">
                                    {id ? <><p className="para">Existing Cover Photo: </p><img src={imgUrl} height="200" style={{ marginBottom: "20px", border: "2px solid #FBAF42" }} /><br /><Button className="submit-btn-small" onClick={() => setModal(true)}>Edit Image</Button></> : ""}
                                    {id ? "" : <> <p className="para">Insert cover photo</p><input type="file" accept=".png,.jpg,.jpeg" className="img-fluid add-work-img" required onChange={e => setCoverImg(e.target.files[0])} name="img" /><br /><br /></>}
                                    {/* {id ? "" : <> <p className="para">Insert Additional Images</p><input type="file" accept=".png,.jpg,.jpeg" className="img-fluid add-work-img" multiple required onChange={function (e) {
                                        if (e.target.files.length > 3) {
                                            e.preventDefault();
                                            alert("You can upload only 3 additional images");
                                            e.target.value = ''
                                        } else {
                                            setAdditionalImages(e.target.files)
                                        }


                                    }} name="img" /></>} */}
                                </Col>
                                <Button className="submit-btn" type="submit">
                                    {loading ? <LoadingSpinner /> : "Submit"}
                                </Button>
                            </Row>
                        </Form>
                    </Row>
                </Container>
            </Row >

            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modal}
                onHide={() => {
                    setModal(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditImage}>
                        <p className="para">insert a new image:</p>
                        <input type="file" accept=".png,.jpg,.jpeg" className="img-fluid add-work-img" required onChange={e => setCoverImg(e.target.files[0])} name="img" />
                        <Button className="submit-btn" type="submit">
                            {loading ? "Updating..." : "Submit"}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={addImageModal}
                onHide={() => {
                    setAddImageModal(false)
                    setCaption('')
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Additional Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditAddImage}>
                        <input type="file" accept=".png,.jpg,.jpeg" className="img-fluid add-work-img" onChange={e => setCoverImg(e.target.files[0])} name="img" style={{ marginBottom: "2em" }} />
                        <Form.Label>Image Caption</Form.Label>
                        <Form.Control as="textarea" name="caption" value={caption || ''} rows={5} type="text" placeholder="Max of 200 words allowed." maxLength="200" onChange={e => setCaption(e.target.value)} />
                        <Button className="submit-btn" type="submit">
                            {loading ? "Updating..." : "Submit"}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={newAddImgModal}
                onHide={() => setNewAddImageModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add a new additional image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitAddImage}>
                        <input type="file" accept=".png,.jpg,.jpeg" className="img-fluid add-work-img" required onChange={e => setCoverImg(e.target.files[0])} name="img" style={{ marginBottom: "2em" }} />
                        <Form.Control as="textarea" name="description" rows={5} type="text" placeholder="caption..." onChange={e => setCaption(e.target.value)} id="des" />
                        <Button className="submit-btn" type="submit">
                            {loading ? "Updating..." : "Submit"}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div >
    )
}

