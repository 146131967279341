import React, { Fragment, useState, useEffect } from 'react';
import './Works.scss';
import WorkOverlay from './WorkOverlay.js';
import { Row, Col, Container, Button, Offcanvas } from 'react-bootstrap';
import axios from 'axios';
import workInfo from '../../../../Global/Images/work-info.png';
import { Timeline, Event } from "react-timeline-scribble";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from 'react-redux';
import { setWork, setWorks } from '../../../../../../store/action/work';
import WorkModal from './WorkModal';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../../../../../firebase';

function Works() {
    const worksSelector = useSelector(state => state.works);
    const workSelector = useSelector(state => state.work);
    const dispatch = useDispatch();

    const [sortedWorks, setSortedWorks] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        try {
            (async () => {
                const workRef = query(collection(db, "work"));
                const workSnap = await getDocs(workRef);

                const tWorks = workSnap.docs.map((doc) => ({
                    doc_id: doc.id,
                    data: doc.data()
                }));

                // Sort works based on index
                const sortedWorks = tWorks.sort((a, b) => a.data.index - b.data.index);

                setSortedWorks(sortedWorks);
                dispatch(setWorks(tWorks));
            })();
        } catch (error) {
            console.log(error);
        }
    }, [dispatch]);

    const handleClickOn = (work) => {
        dispatch(setWork(work));
        setShow(true);
    };

    const handleClickOff = () => {
        setShow(false);
    };

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
            document.body.style.opacity = '1';
        } else {
            document.body.style.overflow = 'unset';
            document.body.style.opacity = '1';
        }
    }, [show]);

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    return (
        <>
            <div className="work-area">
                <Container>
                    <Row>
                        <div className="work-info-right">
                            <Fragment>
                                <Timeline>
                                    {sortedWorks.map((work, index) => (
                                        <Event key={index}>
                                            <div className="margin-work-sec">
                                                <div className="work-info">
                                                    <p className="work-info-name">{work.data.clientName}</p>
                                                    <div className="only-desktop">
                                                        <Button onClick={() => handleClickOn(work)} className="me-2 box-btn">
                                                            Learn More
                                                        </Button>
                                                    </div>
                                                </div>
                                                <p className="work-title">{work.data.title}</p>
                                                <div className="work-info-img-div">
                                                    <img src={work.data.imgUrl} className="img-fluid" alt="past perfect's work" />
                                                </div>
                                                <div className="only-mobile">
                                                    <Button onClick={() => handleClickOn(work)} className="me-2 mt-3 box-btn">
                                                        Learn More
                                                    </Button>
                                                </div>
                                            </div>
                                        </Event>
                                    ))}
                                </Timeline>
                            </Fragment>
                        </div>
                    </Row>
                </Container>
                {show ? (
                    <WorkOverlay
                        client={workSelector.data.clientName}
                        title={workSelector.data.title}
                        des={workSelector.data.description}
                        img={workSelector.data.imgUrl}
                        addImgs={workSelector.data.addImages}
                        toggle={show}
                        onHide={handleClickOff}
                    />
                ) : ""}
            </div>
        </>
    );
}

export default Works;
