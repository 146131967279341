import { React, useState } from "react";
import './auth.scss';
import { Container } from "react-bootstrap";
import { Route, Switch, useRouteMatch } from "react-router";
import Login from "./login/Login";
import Navbar from "../../../ClientSide/Global/navbar/navbar"



const Auth = () => {

    const { path, url } = useRouteMatch();


    return (
        <>
            <Navbar />
            <Container className="auth-container">
                <Switch>
                    <Route path={`${path}/login`} component={Login} />
                </Switch>
            </Container>

        </>
    )
}

export default Auth
