import React from 'react'
import {Row, Col, Container, Navbar, Button} from 'react-bootstrap'

function Hero() {
    return (
        <div className="hero-area">
            <Container>
                <Row>
                    <Col lg={1} md={2} sm={12} xs={12} xl={1}>
                    </Col>
                    <Col lg={5} md={5} sm={12} xs={12} xl={5}>
                    <h1 className="big-header">Our Work</h1>
                    <p className="para mt-3">Institutional journeys, product history, family sagas or even crime-based research, we are always chasing the WHAT WHY and HOW!</p>
                    {/* <a href="#" className="edit-btn-2">Learn More</a> */}
                    </Col>
                    <Col lg={5} md={6} sm={12} xs={12} xl={5} className="side-col">
                    {/* <p className="side-text">20+ Clients</p> */}

                    </Col>
                    {/* <Col lg={5} md={6} sm={12} xs={12} xl={5} className="only-desktop">
                    <div className="side-col">
                        <p className="side-text">estd. 2016</p>
                        <p className="side-text">20+ Clients</p>
                    </div>
                    </Col> */}
                    <div className="only-mobile">
                        <div className="side-mobile">
                            {/* <p className="side-text-1">20+ Clients</p> */}
                        </div>
                    </div>
                </Row>
                </Container>
        </div>
    )
}

export default Hero
