import React,{useState, useCallback, useEffect} from 'react'
import {Container, Navbar, Nav} from 'react-bootstrap';
import logo from '../Images/logo.svg'
// import logo from '../../../AdminSide/Global/images/logo.svg'
import './navbar.scss'
import menuIcon from '../Images/menuIcon.svg'
import Hamburger from '../Hamburger/hamburger.js'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

export default function GlobalNavbar() {

    const [show, setShow] = useState(false);
    const handleToggle = useCallback(() => setShow(prevShow => !prevShow),[])

    useEffect(() => {
        if (show) {
        document.body.style.overflow = 'hidden';
        // document.getElementById('navbar').style.display = 'none';
        } else {
        document.body.style.overflow = 'unset';
        // document.getElementById('navbar').style.display = 'block';
        }
    }, [show]);


    //header ppts
    // const controlNav = () =>{
    //     const currentScrollPos = window.pageYOffset;
    //     console.log(currentScrollPos);
    //     if(currentScrollPos > 100){
    //         document.getElementById('home-logo-id').style.opacity = '0';
    //     }
    //     else{
    //         document.getElementById('home-logo-id').style.opacity = '1';
    //     }
    // }


    // useEffect(() => {
    //     document.addEventListener("wheel", function(event){

    //         if(event.deltaY > 0) {
    //           // we are going down
    //           document.getElementById('home-logo-id').style.opacity = '0';
         
    //         }
         
    //         else {
    //           // we are going up
    //           document.getElementById('home-logo-id').style.opacity = '1';
         
    //         }
    //      })
    // }, []);



    
    
    return (
        <>
            <Navbar fixed="top" id="navbar" className="navbar">
                <Container>
                <Link to="/"><Navbar.Brand><img src={logo} id="home-logo-id" className="img-fluid home-logo"></img></Navbar.Brand></Link>
                <div className="mr-auto"></div>
                {/* <Link to="/dashboard">AdminHome</Link> */}
                <a onClick={handleToggle}><img src={menuIcon} id="menu-logo-id" className="img-fluid menu-logo"></img></a>
                
                </Container>
            </Navbar>
            {show && (
                <Hamburger toggle={handleToggle}/>
            )}
        </>
    )
}
