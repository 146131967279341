import React from 'react'
import Navbar from '../../Global/navbar/navbar'

import Hero from './Components/Hero/Hero.js'
import Verticals from './Components/Verticals/Verticals.js'
import Footer from '../../Global/footer/footer.js'

export default function Ourverticals() {
    return (
        <div className="vertical-page-area">
            <Navbar />
            <Hero />
            <Verticals />
            <Footer />
        </div>
    )
}
