import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";
import './menuItem.scss'


const MenuItem = (props) => {

  const { name, icon, to } = props

  return (
    <div>
      <NavLink to={to} activeClassName="active" className="text-d menuItem">
        <div className="menu-d">
          <i className={icon}></i>
          <div className="menu-link">{name}</div>
        </div>
      </NavLink>
    </div>
  )
}

export default MenuItem