import { React, useEffect, useState } from 'react'
import './Home.scss'
import './authentication/auth.scss'
import logo from '../Global/images/logo.svg';
// import home_icon from '../Global/images/Home_icon.svg'
// import list_icon from '../Global/images/list_icon.svg'
// import work_icon from '../Global/images/work_icon.svg'
// import features_icon from '../Global/images/features_icon.svg'
import MenuItem from '../Global/menuItem/menuItem.js';
import MobileMenuItem from '../Global/menuItem/mobileMenu.js';
import '../Global/menuItem/menuItem.scss'
import dashboard from './dashboard/dashboard.js'
import Enquiries from './enquiries/enquiries.js'
import works from './works/works.js'
import features from './features/features.js'
import AddWork from './works/AddWork/addWork.js'
import AddFeature from './features/AddFeature/addFeature.js'
import AddTeam from './team/addTeam/AddTeam.js'
import Team from './team/Team.js'
import Profile from '../Global/Profile/profile.js'
import jwt_decode from 'jwt-decode';
import { Row, Col, Container, Button, Nav, Navbar } from 'react-bootstrap'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useLocation,
    useRouteMatch
} from "react-router-dom";
import Login from './authentication/login/Login';
import axios from 'axios';
import LoadingSpinner from '../Global/LoadingSpinner';
import Users from './users/users';
import AddUser from './users/AddUser/addUser';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../../../firebase';


const sidebarItems = [
    {
        name: 'Dashboard',
        to: '',
        icon: 'bi bi-grid-1x2'
    },
    {
        name: 'Users',
        to: '/users',
        icon: 'bi bi-person-circle'
    },
    {
        name: 'Enquiries',
        to: '/enquiries',
        icon: 'bi bi-list-ul'
    },
    {
        name: 'Works',
        to: '/works',
        icon: 'bi bi-bag'
    },
    {
        name: 'Features',
        to: '/features',
        icon: 'bi bi-newspaper'
    },
    {
        name: 'Team',
        to: '/team',
        icon: 'bi bi-people'
    }

]

const mobileBarItems = [
    {
        name: 'Dashboard',
        to: '',
        icon: 'bi bi-grid-1x2'
    },
    {
        name: 'Users',
        to: '/users',
        icon: 'bi bi-person-circle'
    },
    {
        name: 'Enquiries',
        to: '/enquiries',
        icon: 'bi bi-list-ul'
    },
    {
        name: 'Works',
        to: '/works',
        icon: 'bi bi-bag'
    },
    {
        name: 'Features',
        to: '/features',
        icon: 'bi bi-newspaper'
    }
    // {
    //     name: 'Team',
    //     to: '/team',
    //     icon: 'bi bi-people'
    // }

]

// async function logoutUser() {

//     // Only when using cookies

//     //     const url = '/logout';
//     //     const headers = {
//     //         'X-CSRF-TOKEN': getCookie('csrf_access_token'),
//     //     }

//     //     return axios.post(url, {}, { headers }).then(function (response) {
//     //         return response;
//     //     }).catch(function (error) {
//     //         return error;
//     //     });

//     localStorage.removeItem('jwt');
// }



const Home = () => {



    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const access_token = localStorage.getItem('jwt');
    const { path, url } = useRouteMatch();
    const [menuItems, setMenuItems] = useState(sidebarItems);
    const [mobileMenuItems, setMobileMenuItems] = useState(mobileBarItems)
    const [user, setUser] = useState({});

    onAuthStateChanged(auth, (currentUser) => [
        setUser(currentUser)
    ])


    const logout = async () => {
        await signOut(auth)
        history.replace('/auth/login');
    }

    // useEffect(() => {
    //     axios.get('https://pastperfect-backend.herokuapp.com/v1/user', { headers }).then((response) => {
    //         setAdmin(response.data.user.admin);
    //         if (admin == false) {
    //             let menu = [...menuItems]
    //             let mobileMenu = [...mobileMenuItems]
    //             menu.splice(1, 1);
    //             mobileMenu.splice(1, 1);
    //             setMenuItems(menu);
    //             setMobileMenuItems(mobileMenu);
    //         }
    //     }).catch((error) => { })
    // }, [admin])


    useEffect(() => {
        if (!user) {
            logout();
        }
    }, [user])


    // const handleLogout = async e => {
    //     e.preventDefault();
    //     setLoading(true);
    //     logout();
    //     setLoading(false);
    // }



    return (
        <div className="h-100">
            <Row>

                <Col lg={2} xl={2} md={3}>

                    <div className="mobile-navbar">
                        <Navbar bg="light" expand="lg" className="mobile-nav">
                            <Container>
                                <Navbar.Brand href="#home"><img src={logo} className="img-fluid logo-mobile"></img></Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        {menuItems.map((menuItem, index) => (
                                            <MenuItem
                                                key={index}
                                                name={menuItem.name}
                                                to={`${url}${menuItem.to}`}
                                                icon={menuItem.icon}
                                            />
                                        ))}
                                        <Profile />
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </div>

                    <div className="mobile-nav-bottom">

                        {mobileMenuItems.map((menuItem, index) => (
                            <MobileMenuItem
                                key={index}
                                name={menuItem.name}
                                to={`${url}${menuItem.to}`}
                                icon={menuItem.icon}
                            />
                        ))}
                    </div>


                    <div className="sidebar">
                        <img src={logo} className="img-fluid logo"></img>

                        {/* nav links */}
                        <div className="menuList">
                            {menuItems.map((menuItem, index) => (
                                <MenuItem
                                    key={index}
                                    name={menuItem.name}
                                    to={`${url}${menuItem.to}`}
                                    icon={menuItem.icon}
                                />
                            ))}

                        </div>
                        <div className="logout-space">
                            <Button onClick={logout} className="logout-btn">{loading ? <LoadingSpinner /> : "Log Out"}</Button>
                        </div>

                    </div>

                </Col>


                <Col lg={10} xl={10} md={9} className="right-dash-h">
                    <Container>
                        <Switch>
                            <Route exact={true} path={`${path}`} component={dashboard} />
                            <Route exact={true} path={`${path}/users`} component={Users} />
                            <Route exact={true} path={`${path}/addUser`} component={AddUser} />
                            <Route exact={true} path={`${path}/enquiries`} component={Enquiries} />
                            <Route exact={true} path={`${path}/works`} component={works} />
                            <Route exact={true} path={`${path}/team`} component={Team} />
                            <Route exact={true} path={`${path}/addTeam`} component={AddTeam} />
                            <Route exact={true} path={`${path}/editTeam/:id`} component={AddTeam} />
                            <Route exact={true} path={`${path}/features`} component={features} />
                            <Route exact={true} path={`${path}/addWork`} component={AddWork} />
                            <Route exact={true} path={`${path}/editwork/:id`} component={AddWork} />
                            <Route exact={true} path={`${path}/editfeature/:id`} component={AddFeature} />
                            <Route exact={true} path={`${path}/addFeature`} component={AddFeature} />
                            <Route exact={true} path={`${path}editTeam/:id`} component={AddTeam} />

                        </Switch>
                    </Container>
                </Col>

            </Row>
        </div>
    );

}

export default Home;
