export const setWorks = (works) => {
    return {
        type: "SET_WORKS",
        payload: works
    }
}

export const setWork = (work) => {
    return {
        type: "SET_WORK",
        payload: work
    }
}

export const getWorks = () => {
    return {
        type: "GET_WORKS"
    }
}