import React from 'react'
import ErrorPageImg from './Components/ClientSide/Global/Images/404.svg'


import instagramLogo from './Components/ClientSide/Global/Images/Instagram.svg'
import fbLogo from './Components/ClientSide/Global/Images/Facebook.svg'
import twitterLogo from './Components/ClientSide/Global/Images/Twitter.svg'
import './App.css'
import {Container, Row, Col, Button} from 'react-bootstrap'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useHistory
} from "react-router-dom";

const ErrorPage = () => {
    return (
        <div className="ErrorArea">
            <Container>
                <Row>
                <Col lg={8} xl={8} md={12} sm={12} xs={12}>
                    {/* <img src={ErrorPageImg} alt="404" className="404"/> */}
                    <h1 className="big-header"><span className="oops">Ooops!</span> This page is yet to be researched. (404)</h1>


                </Col>
                <Col lg={4} xl={4} md={12} sm={12} xs={12}>
                </Col>
                </Row>

                <Row className="row-below">
                <Col lg={8} xl={8} md={12} sm={12} xs={12}>
                </Col>
                <Col lg={4} xl={4} md={12} sm={12} xs={12}>
                    {/* <h1 className="big-header"><span className="oops">Ooops!</span> This page is yet to be researched.</h1> */}
                    <div className="normal">In the meanwhile follow us on social media to get the latest updates.</div>
                    <div className="ham-social mt-4 mb-4">
                        <img src={instagramLogo} className="social-icon" />
                        <img src={fbLogo} className="social-icon" />
                        <img src={twitterLogo} className="social-icon" />
                    </div>
                    <Link to="/"><Button className="box-btn">Go Back to Home Page</Button></Link>

                </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ErrorPage
