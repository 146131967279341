import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Card, Button } from 'react-bootstrap'

import './featured.scss'

import axios from 'axios'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Link,
    useLocation
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../../../../../firebase';
function Featured() {


    let [features, setFeatures] = useState([]);
    // useEffect(() => {
    //     axios.get('https://pastperfect-backend.herokuapp.com/v1/features').then(function (response) {
    //         setFeatures(response.data)
    //     })
    //     let tFeatures = []
    //     try {
    //         (async () => {
    //             const featureRef = query(collection(db, "feature"));
    //             const featureSnap = await getDocs(featureRef);

    //             featureSnap.forEach((doc) => {
    //                 tFeatures.push({
    //                     doc_id: doc.id,
    //                     data: doc.data()
    //                 })
    //             })

    //             setFeatures(tFeatures)

    //         })();
    //     } catch (error) {

    //     }
    // }, []);
    // features = features.slice(0, 3);

    // const allFeatures = [
    //     {
    //         "source": "The Better India",
    //         "date": "June 13, 2020​",
    //         "title": "Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link": "https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img": "https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     },
    //     {
    //         "source": "The Better India",
    //         "date": "June 13, 2020​",
    //         "title": "Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link": "https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img": "https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     },
    //     {
    //         "source": "The Better India",
    //         "date": "June 13, 2020​",
    //         "title": "Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History",
    //         "link": "https://www.thebetterindia.com/229808/history-india-old-pictures-online-libraries-digital-platforms-archives-rare/",
    //         "img": "https://en-media.thebetterindia.com/uploads/2020/06/history-pics.jpg?compress=true&quality=90&w=1100&dpr=0.9",
    //     }
    // ]
    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);
    return (
        <div className="featured-area">
            <Container>
                <div className="featured-box">
                    {/* <Row>
                    <Col xl={1} lg={1} md={1} sm={12} xs={12}></Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                        <h2 className="big-header">Our <br></br>Features</h2>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12} className="info-col">
                        <div className="margin-m-t"></div>
                        <h3 className="normal">Random Sub-header.</h3>
                        <p className="para mt-d">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                        <a href="#" className="edit-btn-2">Read More</a>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}></Col>
                </Row> */}
                    <Row className="row-margin">
                        <Col lg={1} md={1} sm={12} xs={12} xl={1}>
                        </Col>
                        <Col lg={4} md={4} sm={12} xs={12} xl={4}>
                            {/* <p className="top-para">Lorem Ipsum is simply dummy text of </p> */}
                            <h1 className="big-header">Featured.</h1>
                        </Col>
                        <Col lg={3} md={4} sm={12} xs={12} xl={3} className="featured-info">
                            <p className="para">We've caught the attention of history enthusiasts out there. <br></br>Click to read what’s been said about us. </p>
                            <Link to="/features"><a href="#" className="edit-btn-2">Learn More</a></Link>
                        </Col>
                        <Col lg={1} md={1} sm={12} xs={12} xl={1}></Col>
                    </Row>


                    <Container>
                        <Row className="ver-card-row">
                            {/* <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <Card className="feature-card mb-4 p-3">
                                <Card.Body>
                                <div className="mt-3 mb-2"><span className="source">The Better India </span> | <span className="date">June 13, 2020</span></div>
                                    <Card.Title className="card-title-dash mt-4 mb-4">Photo Story: These Rare Pictures Bring to Life Untold Stories From India’s History</Card.Title>
                                    <a href="#" className="edit-btn-2">Read More</a>
                                </Card.Body>
                            </Card>
                    </Col> */}
                            {features.map((feature, index) => (
                                <Col xl={4} lg={4} md={6} sm={12} xs={12} key={index}>
                                    <Card className="feature-card mb-4 p-3" data-aos="fade-up">
                                        <Card.Body>
                                            <div className="mt-3 mb-2"><span className="source">{feature.data.source}</span> | <span className="date">{feature.data.date}</span></div>
                                            <Card.Title className="card-title-dash mt-3 mb-3">{feature.data.title}</Card.Title>
                                            <a href={feature.data.link} target="_blank" className="edit-btn-2">Read More</a>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                            {/* <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                            <button className="box-btn">Read More <i className="bi bi-search ml-3"></i></button>
                    </Col> */}
                        </Row>
                        {/* <Row className="btn-card-row">
                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Link to="/features"><button className="box-btn">Learn More</button></Link>
                        </Col>
                    </Row> */}
                    </Container>
                </div>
            </Container>
        </div>
    )
}

export default Featured
