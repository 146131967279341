import React, { useEffect, useState } from 'react';
import { Route, Link, useHistory } from 'react-router-dom';
import { Row, Col, Container, Pagination, Table, Button } from 'react-bootstrap'
import Profile from '../../Global/Profile/profile.js'
import './enquiries.scss'
import axios from 'axios';
import _ from 'lodash';
import { CSVLink } from 'react-csv';
import { db } from '../../../../firebase.js';
import { collection, getDocs, query, Timestamp } from 'firebase/firestore';


// async function downloadEnquiries() {
//   const url = `https://pastperfect-backend.herokuapp.com/v1/contact/download`;
//   const headers = {
//     "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
//   };
//   return axios.get(url, { headers }).then(function (response) {
//     return response;
//   }).catch(function (error) {
//     return error;
//   })
// }

export default function Enquiries() {

  const [enquiries, setEnquires] = useState([]);

  useEffect(() => {
    let tEnquiries = []
    try {
      (async () => {
        const enquiriesRef = query(collection(db, "contact_form_submissions"));
        const enquiriesSnap = await getDocs(enquiriesRef);
        enquiriesSnap.forEach((doc) => {
          tEnquiries.push({
            doc_id: doc.id,
            data: doc.data()
          })
        })

        setEnquires(tEnquiries)

      })();
    } catch (error) {

    }

  }, []);


  // const pageSize = 10;
  // const pageCount = enquiries? Math.ceil(enquiries.length / pageSize): 0;
  // if (pageCount === 1) {
  //   return null
  // }

  // const pages = _.range(1, pageCount + 1);

  return (
    <div className="enquiry-page">

      <Row>
        <div className="desktop-only"> <Profile /></div>
      </Row>
      <Container>
        <Row className="margin-dash row-margin">

          <h1 className="dashboard-header">Contact Form Enquires</h1>
          <Row className="table">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>received on</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact No.</th>
                  <th>Service Chosen</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {enquiries.map((enquiry, index) => (
                  <tr key={index}>
                    <td>{enquiry.data.timestamp.toDate().toDateString()}</td>
                    <td>{enquiry.data.name}</td>
                    <td>{enquiry.data.email}</td>
                    <td>{enquiry.data.phone_no}</td>
                    <td>{enquiry.data.service}</td>
                    <td><span className="text-l">{enquiry.message}</span><br></br><br></br></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        </Row>

      </Container>
      {/* <Row>
        <div className="pagination">
          <ul className="paginate-list">
            <li className="paginate-item"><span aria-hidden="true">&laquo;</span></li>
            <li className="paginate-item">1</li>
            <li className="paginate-item">2</li>
            <li className="paginate-item">3</li>
            <li className="paginate-item">4</li>
            <li className="paginate-item">5</li>
            <li className="paginate-item"><span aria-hidden="true">&raquo;</span></li>
          </ul>
        </div>
      </Row> */}
    </div>
  )
}
