import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyCCeusNK-69lGARFOlsX3h4dYQfKc0YEOY",
    authDomain: "pastperfect-backend.firebaseapp.com",
    projectId: "pastperfect-backend",
    storageBucket: "pastperfect-backend.appspot.com",
    messagingSenderId: "766829560469",
    appId: "1:766829560469:web:8c7166b1888cd8272a519c"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage()