import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { React, useState, useEffect } from "react";
import Content from './Content.js'
import { Container, Row, Col } from 'react-bootstrap'
import logo from './Components/ClientSide/Global/Images/logo.svg';


// import dashboard from './Components/AdminSide/Screens/dashboard/dashboard.js'
// import enquiries from './Components/AdminSide/Screens/enquiries/enquiries.js'
// import works from './Components/AdminSide/Screens/works/works.js'
// import features from './Components/AdminSide/Screens/features/features.js'

// const routes = [
//   {
//     path: "/dashboard",
//    component: "dashboard"
//   },
//   {
//     path: "/enquiries",
//     component:"enquiries"
//   }

// ];

function App() {
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000);

  }, []);

  return (
    <div className="App">
      {
        loading ?
          <div className="preLoadArea">
            <Container className="text-center">
              <Row className="align-items-center h-100">
                <Col>
                  <div className="pre-load-logo">
                    <img src={logo} id="menu-logo-id" className="img-fluid preload-logo"></img>
                    <p className="para mt-5">Loading...</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          :
          <Content />
      }
    </div>
  );
}

export default App;
