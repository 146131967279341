import React, { useEffect } from 'react'
import './contactCTA.scss'
import { Container, Col, Row, Card, Button } from 'react-bootstrap'
import ContactCta from '../Images/contact_cta.jpg'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Link,
    useLocation
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";


function ContactCTA() {
    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);
    return (
        <div className="contactCTA-area">
            <Container>
                <Row className="pad-con">
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <img src={ContactCta} className="contactCTA-img img-fluid" alt="Pastperfect team working." />
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} xs={12} className="con-info-col">
                        <h2 className="big-header header-con">Let's <br></br>Talk.</h2>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12} className="con-info-col con-info-right-col">
                        <p className="para mt-4 mb-4">Confused about where and how to begin?
<br></br><br></br>We'd recommend starting with an exploratory conversation to assess the possibilities.</p>
                        <Link to="/contact-us"><button className="box-btn">Contact Us</button></Link>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactCTA
