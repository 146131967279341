import React from 'react'
import { Row, Col, Container, Card } from 'react-bootstrap'
import Profile from '../../Global/Profile/profile.js'
import './dashboard.scss'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Link,
    useLocation,
    useHistory
} from "react-router-dom";

export default function dashboard() {
    return (
        <div>
            <Row>
                <div className="desktop-only"> <Profile /></div>
            </Row>
            <Row className="margin-dash row-margin">
                <Col>
                    <Container>
                        <h1 className="dashboard-header">Hey there,</h1>
                        <p className="para mt-3">Lorem Ipsum is simply dummy text of the<br></br> printing and typesetting industry.</p>

                        <Row className="margin-dash-2">
                            <Col lg={4} xl={4} md={6} sm={12} xs={12} className="mb-4">

                                <Card className="dash-card">
                                    <Card.Body>
                                        <Card.Title className="card-title-dash">All your Contact Form<br></br> Enquires, over here</Card.Title>
                                        <div className="margin-card-dash"></div>
                                        <Link to="/dashboard/enquiries"><Card.Link className="learn-more-btn">Learn More</Card.Link></Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4} xl={4} md={6} sm={12} xs={12} className="mb-4">
                                <Card className="dash-card">
                                    <Card.Body>
                                        <Card.Title className="card-title-dash">Create, View, Update,<br></br> Delete yOur Work.<br></br> Over here</Card.Title>
                                        <div className="margin-card-dash"></div>
                                        <Link to="/dashboard/works"><Card.Link className="learn-more-btn">Learn More</Card.Link></Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4} xl={4} md={6} sm={12} xs={12} className="mb-4">
                                <Card className="dash-card">
                                    <Card.Body>
                                        <Card.Title className="card-title-dash">Create, View, Update,<br></br> Delete your features.<br></br> Over here</Card.Title>
                                        <div className="margin-card-dash"></div>
                                        <Link to="/dashboard/features"><Card.Link className="learn-more-btn">Learn More</Card.Link></Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} xl={4} md={6} sm={12} xs={12} className="mb-4">

                                <Card className="dash-card">
                                    <Card.Body>
                                        <Card.Title className="card-title-dash">User having access to this<br></br> dashboard.</Card.Title>
                                        <div className="margin-card-dash"></div>
                                        <Link to="/dashboard/users"><Card.Link className="learn-more-btn">Learn More</Card.Link></Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4} xl={4} md={6} sm={12} xs={12} className="mb-4">
                                <Card className="dash-card">
                                    <Card.Body>
                                        <Card.Title className="card-title-dash">Create, View, Update, Delete Team Members. </Card.Title>
                                        <div className="margin-card-dash"></div>
                                        <Link to="/dashboard/team"><Card.Link className="learn-more-btn">Learn More</Card.Link></Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4} xl={4} md={6} sm={12} xs={12} className="mb-4">
                                
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </div>
    )
}
